import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db, storage } from "../firebase/Config";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ClipboardJS from "clipboard";

new ClipboardJS(".clpButton");

function EmbedInfo() {
  const [uid, setUid] = useState("");
  const [company, setCompany] = useState("");

  const getUser = async () => {
    // console.log("Inside getUser Func");
    const auth = await getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      // const uid = user.uid;
      setUid(user.uid);

      // console.log("uid", uid);
    } else {
      console.log("user is not signed in");
    }
  };

  const getCompany = async () => {
    const q = query(collection(db, "company"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setCompany(doc.data().name);
      // console.log("company", doc.data());
    });
  };

  useEffect(() => {
    getUser();
    uid && getCompany();
  }, [uid]);

  useEffect(() => {
    getUser();
  });

  return (
    <div className="flex justify-center mt-10">
      <div className="flex-col justify-center w-1/2 ">
        <div className="flex justify-center mt-5">
          <div className="block p-6 rounded-lg shadow-lg bg-white max-w-full">
            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
              Copy below generated code and paste it into your website's HTML
              element:
            </h5>
            <p className="text-gray-700 text-base mb-4 " id="code">
              {`<iframe height="100%" width="100%" src="https://app.solidhire.io/embed/${company}"><iframe>`}
            </p>
            {/* <button
              type="button"
              className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              Button
            </button> */}
            <button
              className="clpButton inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              data-clipboard-action="copy"
              data-clipboard-target="#code"
              onClick={() => {
                window.alert("Code copied to clipboard");
              }}
            >
              Copy the code
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmbedInfo;
