import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { db } from "../firebase/Config";
import validator from "validator";
import { async } from "@firebase/util";
import Roles from "../components/Roles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import algoliasearch from "algoliasearch";

function Introduction({ companyNameFromIntroduction }) {
  const [uid, setUid] = useState(null);
  const [userName, setUserName] = useState("");
  const [title, setTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [about, setAbout] = useState("");
  const [willDo, setWillDo] = useState("");
  const [mustHave, setMustHave] = useState("");
  const [hobbies, setHobbies] = useState("");

  const [userCheck, setUserCheck] = useState(false);
  const [modal, setModal] = useState(false);
  const [main, setMain] = useState(true);
  const [instructions, setInstructions] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [logo, setLogo] = useState(null);
  const [progress, setProgress] = useState(0);
  const [todaysDate, setTodaysDate] = useState("");

  const [taskInput, setTaskInput] = useState("");
  const [loading, setLoading] = useState(true);

  const [existingRolesChecker, setExistingRolesChecker] = useState(false);
  const [random, setRandom] = useState(null);
  const [arrayUid, setArrayUid] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [requestModal, setRequestModal] = useState(false);
  const [requestedTitle, setRequestedTitle] = useState("");

  const getTodaysDate = () => {
    let currentDate = new Date();
    let cDay = currentDate.getDate();
    let cMonth = currentDate.getMonth() + 1;
    let cYear = currentDate.getFullYear();
    setTodaysDate(cDay + "-" + cMonth + "-" + cYear);
  };

  const getUser = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      setUid(user.uid);
      setUserName(user.displayName);
    } else {
      console.log("user not found");
    }
  };

  const checkExistingUser = async () => {
    const q = query(collection(db, "company"), where("uid", "==", uid));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      // console.log("length", querySnapshot.docs.length);
      querySnapshot.forEach((doc) => {
        setCompanyName(doc.data().name);
        setModal(false);
        setMain(true);
      });
    } else {
      setModal(true);
      setMain(false);
    }

    // querySnapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    //   console.log(doc.id, " => ", doc.data());
    // });
    setLoading(false);
  };

  const checkExistingRoles = async () => {
    let x = [];
    const q = query(
      collection(db, "roles"),
      where("uid", "array-contains", uid)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      x = querySnapshot.docs[0].data().uid;
      setRandom(querySnapshot.docs.length);
      setExistingRolesChecker(true);
      // console.log("length", querySnapshot.docs[0].data().uid);
      querySnapshot.forEach((doc) => {
        // console.log("inside QS");
      });
    } else {
      console.log("already Roles DONT exist");
      setExistingRolesChecker(false);
    }
    if (x.length !== 0) {
      setArrayUid(x);
      // console.log("x", x);
    } else {
      setArrayUid([uid]);
    }
  };

  useEffect(() => {
    uid && checkExistingRoles();
    // console.log("uid in useeffect", arrayUid);
  }, [uid]);

  const onSubmit = async () => {
    if (
      !validator.isEmpty(uid) &&
      !validator.isEmpty(title) &&
      !validator.isEmpty(department) &&
      !validator.isEmpty(type) &&
      !validator.isEmpty(location) &&
      !validator.isEmpty(about) &&
      !validator.isEmpty(willDo) &&
      !validator.isEmpty(mustHave) &&
      !validator.isEmpty(hobbies)
    ) {
      await addDoc(collection(db, "roles"), {
        uid: arrayUid,
        title: title,
        department: department,
        type: type,
        location: location,
        about: about,
        willDo: willDo,
        mustHave: mustHave,
        hobbies: hobbies,
        company: companyName,
        date: todaysDate,
        archive: false,
      })
        .then(() => {
          resetStates();
          companyNameFromIntroduction(companyName);
          notify();
        })
        .catch((error) => console.log(error));
    } else {
      alertForFillingAllDetails();
    }
  };

  const notify = () => toast.success("Job Successfully Added!");

  const alertForFillingAllDetails = () =>
    toast.warn("Please fill all details.");

  //alert if same company name exist
  const alertForSameCompanyName = () =>
    toast.warn("Company Name already exist. Try putting in full Name.");

  const alertForSubmittingTemplateRequest = () => {
    toast.success("Request for JD template submitted successfully!");
  };

  const resetStates = () => {
    setTitle("");
    setDepartment("");
    setType("");
    setLocation("");
    setAbout("");
    setWillDo("");
    setMustHave("");
    setHobbies("");
  };

  useEffect(() => {
    checkExistingUser();
    getUser();
    getTodaysDate();
  });

  const onLogoSubmit = async () => {
    const storage = getStorage();
    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, "logo/" + companyName + ".jpg");
    const uploadTask = uploadBytesResumable(storageRef, logo);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progres = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progres + "% done");
        setProgress(progres);

        // console.log("completion", snapshot.state);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }
      // setProgress("98"),
      // setApply(null)
      // () => {
      //   // Upload completed successfully, now we can get the download URL
      //   getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      //     console.log("File available at", downloadURL);
      //   });
      // }
    );
  };

  const onCompanySubmit = async () => {
    // console.log(companyName);
    if (!validator.isEmpty(companyName) && !validator.isEmpty(aboutCompany)) {
      await addDoc(collection(db, "company"), {
        uid: uid,
        name: companyName,
        about: aboutCompany,
      });
      onLogoSubmit();
      setModal(false);
      // setMain(true);
      setInstructions(true);
    } else {
      alertForFillingAllDetails();
    }
    setProgress(100);
  };

  //check if same company name exist in database

  const checkExistingCompany = async () => {
    const q = query(
      collection(db, "company"),
      where("name", "==", companyName)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      // console.log("length", querySnapshot.docs.length);

      alertForSameCompanyName();
    } else {
      onCompanySubmit();
    }
  };

  //submit JD request
  const requestForTemplate = async () => {
    // console.log(companyName);
    if (!validator.isEmpty(requestedTitle)) {
      await addDoc(collection(db, "template_request"), {
        uid: uid,
        name: companyName,
        title: requestedTitle,
      });

      setRequestModal(!requestModal);
      // setMain(true);
      setRequestedTitle("");
      alertForSubmittingTemplateRequest();
    } else {
      alertForFillingAllDetails();
    }
    setProgress(100);
  };

  const searchForJobTemplates = () => {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      process.env.REACT_APP_ALGOLIA_API_KEY
    );
    const index = client.initIndex("job_templates");

    index
      .search(searchTerm)
      .then(({ hits }) => {
        // console.log(hits);
        setSearchData(hits);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    searchForJobTemplates();
  }, [searchTerm]);

  return loading ? (
    <div className="relative z-10">
      <div className="fixed   top-52 left-0 right-0 text-center">
        <svg
          role="status"
          className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
    </div>
  ) : (
    <div>
      {modal ? (
        <div className="modal fade fixed top-40 left-1/4 rounded-md p-2 bg-white shadow-2xl shadow-black z-10  w-1/2 justify-self-center  outline-none overflow-x-hidden overflow-y-auto ">
          <div className="modal-dialog relative w-auto m-2 ">
            <h1>
              *(If you are a part of existing User Company, please go to Teams
              Section and generate UID code and share it with your Admin account
              holder. If you are a new User looking to register, please continue
              below:) <br />
              <br />
              Hello and Welcome {userName}! <br />
              Before we start, please help us with few details necessary for
              great experience.
              <br />
              <br />
              Please Enter your Company's name:
            </h1>
            <input
              className="border-2 w-full mt-2 rounded-md mb-2"
              onChange={(e) =>
                setCompanyName(e.target.value.toLowerCase().trim())
              }
            ></input>
            <div>About your company (in brief):</div>
            <textarea
              className="border-2 w-full mt-2 rounded-md"
              onChange={(e) => setAboutCompany(e.target.value)}
            ></textarea>
            <div>Upload company's logo:</div>
            <input
              className="border-2 w-full mt-2 rounded-md"
              type="file"
              onChange={(e) => setLogo(e.target.files[0])}
            ></input>
            <button
              className="py-2 px-4 mt-2  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
              onClick={checkExistingCompany}
            >
              Submit
            </button>
          </div>
        </div>
      ) : null}

      {instructions ? (
        <div className="modal fade fixed top-40 left-1/4 rounded-md p-2 bg-white shadow-2xl shadow-black z-10  w-1/2 justify-self-center  outline-none overflow-x-hidden overflow-y-auto ">
          <div className="modal-dialog relative w-auto m-2 ">
            <h1>
              Thank you for the details!
              <br />
              Let's start by entering your requirements.
              <br />
              Once entered, you can click on "All Openings" tab in Navigation
              bar to see all of the openings entered by you.
            </h1>
            {/* <input
              className="border-2 w-full mt-2 rounded-md mb-2"
              onChange={(e) => setCompanyName(e.target.value.toLowerCase())}
            ></input>
            <div>About your company (in brief):</div>
            <textarea
              className="border-2 w-full mt-2 rounded-md"
              onChange={(e) => setCompanyName(e.target.value.toLowerCase())}
            ></textarea> */}
            <button
              className="py-2 px-4 mt-2  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
              onClick={() => {
                setInstructions(false);
                setMain(true);
              }}
            >
              Let's Start
            </button>
          </div>
        </div>
      ) : null}

      {main ? (
        <div className="flex flex-col justify-center items-center m-5 ">
          {/* <h1>Hello {userName}</h1>
          <h2>Let's get you started</h2> */}

          {/* Modal for JD request */}
          {requestModal ? (
            <div className="w-1/2 flex justify-center">
              <div className="modal fade fixed z-10 shadow-2xl  border-2 ">
                <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                  <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div className="modal-header flex flex-shrink-0 items-center justify-between p-4  border-gray-200 rounded-t-md">
                      <h5
                        className="text-xl font-medium leading-normal text-gray-800"
                        id="exampleModalScrollableLabel"
                      >
                        Request for a Job description:
                      </h5>
                    </div>
                    <div className="modal-body relative px-4">
                      <div>Title:</div>
                      <input
                        placeholder="React developer"
                        className=" border w-full rounded-md"
                        onChange={(e) => setRequestedTitle(e.target.value)}
                      ></input>
                    </div>
                    <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4  rounded-b-md">
                      <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setRequestModal(!requestModal);
                          setRequestedTitle("");
                        }}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          requestForTemplate();
                        }}
                        className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <h3 className="font-semibold text-left w-1/2">
            Search for best Job description from our 1000+ templates:
            {/* <a
              className="underline"
              href=" https://docs.google.com/spreadsheets/d/127p4mWvTYptqANfaX8CKco2FutBcyuKF-54SJnyBQ8Q/edit?usp=sharing"
              target="_blank"
            >
              {" "}
              Click here
            </a> */}
          </h3>
          {/* SearchBox */}
          <div className="flex  rounded-md w-1/2 justify-center">
            <div className="mb-3 w-full">
              <div className="input-group relative flex  items-stretch w-full mb-4">
                <input
                  type="search"
                  className="mr-2 form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Web Developer"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* <button
                  className="btn inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                  type="button"
                  id="button-addon2"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="search"
                    className="w-4"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                    ></path>
                  </svg>
                </button> */}
              </div>

              {searchTerm ? (
                <div className="border rounded-md">
                  {searchData.map((s) => {
                    // console.log(s);
                    return (
                      <div
                        className="border-b-1 m-1 cursor-pointer"
                        onClick={(e) => {
                          setTitle(s["Title"]);
                          setAbout(s["About the Role"]);
                          setMustHave(s["Candidate must Have"]);
                          setWillDo(s["Candidate will do:"]);
                          setSearchTerm("");
                        }}
                      >
                        {s.Title}
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {/* ask for job templates */}
              <div className="w-1/2 text-left text-sm">
                <div>
                  Can't find the right JD?{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => setRequestModal(true)}
                  >
                    Request for one
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* form  */}
          <div className="text-left w-1/2">
            Fill below Form to enter new openings at your Organization:
          </div>
          <form className="w-1/2 border-2 p-2 rounded-md m-5 mt-1">
            <h1>Title</h1>
            <input
              className="border-2 w-full"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></input>
            <h1>Department</h1>
            <input
              className="border-2 w-full"
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
            ></input>
            <h1>Type</h1>
            <input
              className="border-2 w-full"
              value={type}
              onChange={(e) => setType(e.target.value)}
            ></input>
            <h1>Location</h1>
            <input
              className="border-2 w-full"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            ></input>
            <h1>About the Role</h1>
            <textarea
              type="text"
              className="border-2 w-full"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            ></textarea>
            <h1>What candidate will do?</h1>
            <textarea
              className="border-2 w-full"
              value={willDo}
              onChange={(e) => setWillDo(e.target.value)}
            ></textarea>
            <h1>Candidate must have</h1>
            <textarea
              className="border-2 w-full"
              value={mustHave}
              onChange={(e) => setMustHave(e.target.value)}
            ></textarea>
            <h1>Candidate is</h1>
            <textarea
              className="border-2 w-full"
              value={hobbies}
              onChange={(e) => setHobbies(e.target.value)}
            ></textarea>
            <button
              type="button"
              className="py-2 px-4 mt-2  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
              onClick={onSubmit}
            >
              Submit
            </button>
            <button
              type="button"
              className="py-2 px-4 mt-2  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
              onClick={() => resetStates()}
            >
              Clear
            </button>
          </form>
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
}

export default Introduction;
