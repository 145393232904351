import React, { useState, useEffect } from "react";
import { db } from "../firebase/Config";
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { Link, Redirect, withRouter, useHistory } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { map } from "@firebase/util";
import SendGrid from "./SendGrid";
import moment from "moment";
import { Chart } from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import fuzzysearch from "fuzzysearch";
import { MdClear, MdOutlineShare } from "react-icons/md";
import ShareLink from "react-linkedin-share-link";

function Roles(props) {
  const [roles, setRoles] = useState([]);
  const [uid, setUid] = useState("");
  const [qs, setQs] = useState(null);
  const [modal, setModal] = useState(false);
  const [clickedRole, setClickedRole] = useState("");
  const [filteredRole, setFilteredRole] = useState([]);
  const [titleToDelete, setTitleToDelete] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [applications, setApplications] = useState([]);
  const [comp, setComp] = useState(null);
  const [arrSorted, setArrSorted] = useState([]);
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const [applicantsDataForGraph, setApplicantsDataForGraph] = useState([]);

  const [search, setSearch] = useState("");

  //sort dates
  function sortDates(a, b) {
    return b.date - a.date;
  }

  // const uid = "";
  const getUser = () => {
    // console.log("Inside getUser Func");
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      // const uid = user.uid;
      setUid(user.uid);

      // console.log("uid", uid);
    } else {
      console.log("user is not signed in");
    }
  };

  const readData = async () => {
    setLoading(true);
    // console.log("Inside readData Func");
    const arr = [];
    const arr1 = [];
    var arr2 = [];
    // console.log("inside readData", uid);

    const q = query(
      collection(db, "roles"),

      where("uid", "array-contains", uid),
      where("archive", "==", false)
    );

    const querySnapshot = await getDocs(q);
    // console.log(Date(serverTimestamp()).getDate());

    // console.log("querySnapshoot", querySnapshot);
    // console.log(uid);
    // console.log(querySnapshot.docs);

    if (querySnapshot.docs.length > 0) {
      // setRoles(querySnapshot.docs);

      // setQs(querySnapshot.docs);
      querySnapshot.forEach((doc) => {
        // console.log(doc);
        arr.push(doc.data());
        // console.log("Yooo", arr);

        // console.log(arr);
        // setRoles(arr);
        // console.log(roles);
      });
      setRoles(arr);

      setComp(arr[0].company);
      rolesSortedByDate();
    } else {
      // console.log("querySnapshoot", querySnapshot);
      // console.log(querySnapshot.docs);
      window.location.pathname = `/introduction`;
      // setQs(undefined);
    }
  };

  const rolesSortedByDate = () => {
    const arr1 = [];
    var arr2 = [];

    roles.map((role) => {
      const [dd, mm, yyyy] = role.date.toString().split("-");
      const newD = [yyyy, mm, dd].join("-");
      role.date = new Date(newD);
      arr1.push(role);
    });

    arr2 = arr1.sort(sortDates);

    setArrSorted(arr2);

    // console.log(arr2);

    setLoading(false);

    // arrSorted && console.log("arrSorted", arrSorted);
  };

  // useEffect(() => {
  //   // console.log("useEffect", arrSorted);
  //   // console.log("roles", roles);
  // }, [arrSorted]);

  const deleteRole = async (t) => {
    const q = query(collection(db, "roles"), where("title", "==", t));

    try {
      const querySnapshot = await getDocs(q);
      const id = querySnapshot.docs[0].id;
      await deleteDoc(doc(db, "roles", id));
      console.log("doc deleted");
      setPopUp(false);
      setModal(false);
      readData();
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getUser();
    comp && getApplications();
    uid &&
      readData().then(() => {
        setLoading(false);
      });
  }, [uid, comp]);

  useEffect(() => {
    roles && rolesSortedByDate();
    roles && getDataForGraphs();
    roles && getDataForGraph2();
  }, [roles]);

  const getDataForGraphs = () => {
    const applicationsDates = [];

    applications.map((item) => {
      if (item.date) {
        const [dd, mm, yyyy] = item.date.toString().split("-");
        const newD = [yyyy, mm, dd].join("-");
        item.date = new Date(newD);
      } else {
        item.date = new Date("2022-01-01");
      }

      // applicationsDates.push(moment(item.date).format("MMMM'YY"));
      applicationsDates.push(item.date);
    });

    const applicationsDatesSorted = applicationsDates.sort((a, b) => {
      return a - b;
    });

    const formattedArray = applicationsDatesSorted.map((item) => {
      return moment(item).format("MMMM'YY");
    });

    const reducedArray = formattedArray.reduce((acc, curr) => {
      if (acc[curr]) {
        acc[curr]++;
      } else {
        acc[curr] = 1;
      }
      return acc;
    }, {});

    const finalData = Object.keys(reducedArray).map((key) => {
      return [key, reducedArray[key]];
    });

    const some = finalData.sort((a, b) => {
      return (
        moment(a[0], "MMMM'YY").toDate() - moment(b[0], "MMMM'YY").toDate()
      );
    });

    setGraphData(finalData);
  };

  const data4Graph = {
    labels: graphData.map((item) => item[0]),
    datasets: [
      {
        // lineTension: 0.3,
        label: "Applicants",
        backgroundColor: "red",
        borderColor: "rgba(255,99,132)",
        data: graphData.map((item) => item[1]),
        ticks: {
          beginAtZero: false,
        },
        animations: {
          tension: {
            duration: 2000,
            easing: "linear",
            from: 0,
            to: 0.4,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
  };

  const getStatusDataForRole = (role) => {
    const DataObject = {
      applied: 0,
      rejected: 0,
      shortlisted: 0,
      interviewed: 0,
      "interviewed-2": 0,
      "interviewed-3": 0,
      selected: 0,
    };
    applications.map((item) => {
      if (item.role === role) {
        DataObject[item.status] += 1;
      }
      return DataObject;
    });

    console.log("Dataobject", DataObject);
  };

  const getDataForGraph2 = () => {
    //get statusDataforRole for each role and store it in a new array

    const arr = [];

    // roles.map((role) => {
    //   getStatusDataForRole(role.title);
    //   arr.push([role.title, DataObject]);
    // });

    roles.map((role) => {
      const DataObject = {
        applied: 0,
        rejected: 0,
        shortlisted: 0,
        interviewed: 0,
        "interviewed-2": 0,
        "interviewed-3": 0,
        selected: 0,
      };
      applications.map((item) => {
        if (item.role === role.title) {
          DataObject[item.status] += 1;
        }
        return DataObject;
      });
      arr.push([role.title, DataObject]);
    });

    setApplicantsDataForGraph(arr);

    // console.log("arr", arr);
  };

  const data4Graph2 = {
    labels: applicantsDataForGraph.map((item) => item[0]),
    datasets: [
      {
        label: "Applied",
        backgroundColor: "#a8e6cf",
        borderColor: "rgba(255,99,132)",
        data: applicantsDataForGraph.map((item) => item[1].applied),
        ticks: {
          beginAtZero: false,
        },
        animations: {
          tension: {
            duration: 2000,
            easing: "linear",
            from: 0,
            to: 0.4,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        label: "Rejected",
        backgroundColor: "#dcedc1",
        borderColor: "rgba(255,99,132)",
        data: applicantsDataForGraph.map((item) => item[1].rejected),
        ticks: {
          beginAtZero: false,
        },
        animations: {
          tension: {
            duration: 2000,
            easing: "linear",
            from: 0,
            to: 0.4,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        label: "Shortlisted",
        backgroundColor: "#ffd3b6",
        borderColor: "rgba(255,99,132)",
        data: applicantsDataForGraph.map((item) => item[1].shortlisted),
        ticks: {
          beginAtZero: false,
        },
        animations: {
          tension: {
            duration: 2000,
            easing: "linear",
            from: 0,
            to: 0.4,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        label: "Interviewed",
        backgroundColor: "#ffaaa5",
        borderColor: "rgba(255,99,132)",
        data: applicantsDataForGraph.map((item) => item[1].interviewed),
        ticks: {
          beginAtZero: false,
        },
        animations: {
          tension: {
            duration: 2000,
            easing: "linear",
            from: 0,
            to: 0.4,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        label: "Interviewed-2",
        backgroundColor: "#ff8b94",
        borderColor: "rgba(255,99,132)",
        data: applicantsDataForGraph.map((item) => item[1]["interviewed-2"]),
        ticks: {
          beginAtZero: false,
        },
        animations: {
          tension: {
            duration: 2000,
            easing: "linear",
            from: 0,
            to: 0.4,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        label: "Interviewed-3",
        backgroundColor: "#ffeead",
        borderColor: "rgba(255,99,132)",
        data: applicantsDataForGraph.map((item) => item[1]["interviewed-3"]),
        ticks: {
          beginAtZero: false,
        },
        animations: {
          tension: {
            duration: 2000,
            easing: "linear",
            from: 0,
            to: 0.4,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        label: "Selected",
        backgroundColor: "#ffcc5c",
        borderColor: "rgba(255,99,132)",
        data: applicantsDataForGraph.map((item) => item[1].selected),
        ticks: {
          beginAtZero: false,
        },
        animations: {
          tension: {
            duration: 2000,
            easing: "linear",
            from: 0,
            to: 0.4,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
  };

  const optionsForGraph2 = {
    plugins: {
      // title: {
      //   display: true,
      //   text: 'Chart.js Bar Chart - Stacked',
      // },
    },

    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  // useEffect(() => {
  //   console.log(clickedRole);
  //   setFilteredRole(roles.filter((role) => role.title === clickedRole));
  //   clickedRole && setModal(true);
  // }, [clickedRole]);

  const getApplications = async () => {
    const arr = [];
    const q = query(
      collection(db, "candidates"),
      where("userId", "array-contains", uid),
      where("company", "==", comp)
    );
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });

      // console.log(arr.length);
      setApplications(arr);
      // console.log("applications", arr);
    } catch (e) {
      console.log(e);
    }

    // setApplications(arr.length());

    // setDocId(docs);
    // console.log("candidate state", candidate, docId);
  };

  const updateArchive = async (t) => {
    const q = query(collection(db, "roles"), where("title", "==", t));

    try {
      const querySnapshot = await getDocs(q);
      const id = querySnapshot.docs[0].id;
      await updateDoc(doc(db, "roles", id), {
        archive: true,
      });

      console.log("doc archived");

      // setModal(!modal);
      readData();
    } catch (e) {
      console.log(e.message);
    }
  };

  const filterApplicants = (r) => {
    let score = 0;
    applications.forEach((a) => {
      if (a.role === r) {
        score += 1;
      }
      score += 0;
    });
    // console.log("score", score);
    return score;
  };

  const history = useHistory();

  return loading ? (
    <div className="relative z-10">
      <div className="fixed   top-52 left-0 right-0 text-center">
        <svg
          role="status"
          className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
    </div>
  ) : (
    <div className="flex w-screen  pl-20">
      <div className="flex justify-center w-full">
        <div className="flex-col content-center justify-center w-4/5  text-center">
          <div className="w-6/12 mt-10 mb-10 justify-right flex items-center content-center">
            <Line className="" data={data4Graph} />
            <Bar className="" options={optionsForGraph2} data={data4Graph2} />
          </div>

          <div className="mt-5 text-xl text-left w-full font-semibold">{`Openings: ${roles.length}`}</div>
          <div className="flex justify-between items-center w-full outline outline-1 rounded-lg pl-2 mt-2 mb-2">
            <input
              className="w-full"
              type="text"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              placeholder="Search roles"
            />
            {search ? (
              <MdClear
                className="cursor-pointer"
                onClick={() => setSearch("")}
              />
            ) : null}
          </div>
          {/* table starts */}
          <div className="flex  justify-center  rounded-xl ">
            <table className="w-full text-sm mt-2 text-left rounded-md text-gray-500 dark:text-gray-400">
              <thead className="text-xs rounded text-black uppercase bg-gray-150 dark:bg-gray-200 ">
                <tr>
                  <th scope="col" className="px-2 py-3">
                    Title
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Department
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Location
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Posted-on (MM/DD/YYYY)
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Applied
                  </th>
                  <th scope="col" className="px-2 py-3"></th>
                  <th scope="col" className="px-2 py-3"></th>
                  <th scope="col" className="px-2 py-3"></th>
                  <th scope="col" className="px-2 py-3"></th>
                  {/* <th scope="col" class="px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th> */}
                </tr>
              </thead>

              {arrSorted
                .filter(
                  (role) =>
                    fuzzysearch(
                      search.toLowerCase(),
                      role.title.toLowerCase()
                    ) ||
                    fuzzysearch(
                      search.toLowerCase(),
                      role.department.toLowerCase()
                    ) ||
                    fuzzysearch(
                      search.toLowerCase(),
                      role.location.toLowerCase()
                    )
                )
                .map((role) => {
                  return (
                    <>
                      <tbody
                        className="text-gray-800"
                        id={role.title}
                        key={Date.now()}
                        onClick={(e) => {
                          if (!e.target.id) {
                            //Do nothing
                          } else {
                            // setFilteredRole(
                            //   roles.filter((role) => role.title === e.target.id)
                            // );
                            // setModal(true);
                            // props.onRoleSelect(role.title);
                            // <Link to={`/home/${role.title}`} />;
                            history.push(`/home/${role.title}`);
                          }
                        }}
                      >
                        <tr
                          className="border-b cursor-pointer dark:border-gray-700 odd:bg-white even:bg-gray-50 "
                          id={role.title}
                          // onClick={(e) => {
                          //   setClickedRole(e.target.id);
                          // }}
                        >
                          <th
                            scope="row"
                            className="px-2 py-4 font-medium text-black whitespace-nowrap"
                            id={role.title}
                          >
                            {role.title}
                          </th>
                          <td className="px-2 py-4" id={role.title}>
                            {role.department}
                          </td>
                          <td className="px-2 py-4" id={role.title}>
                            {role.location}
                          </td>
                          <td className="px-2 py-4 " id={role.title}>
                            {moment(role.date).calendar()}
                          </td>
                          <td className="px-2 py-4 " id={role.title}>
                            {filterApplicants(role.title)}
                          </td>
                          <td className="px-2 py-4 ">
                            <button
                              type="button"
                              className="px-3
                      py-2.5
                      bg-yellow-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-yellow-700 hover:shadow-lg
                      focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-yellow-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => {
                                updateArchive(role.title);
                              }}
                            >
                              Archive
                            </button>
                          </td>
                          <td className="px-2 py-4 ">
                            <button
                              type="button"
                              className="px-3
                      py-2.5
                      bg-red-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-red-700 hover:shadow-lg
                      focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-red-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => {
                                setTitleToDelete(role.title);
                                setPopUp(true);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                          <td className="px-2 py-4 ">
                            <button
                              type="button"
                              className="px-3
                    py-2.5
                    bg-blue-600
                    text-white
                    font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-blue-700 hover:shadow-lg
                    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-blue-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out
                    ml-1"
                              onClick={() => {
                                // props.setCandidate(filteredRole[0].title);
                                setFilteredRole(role, setModal(true));
                              }}
                            >
                              Details
                            </button>
                          </td>
                          <td>
                            <ShareLink
                              link={`https://app.solidhire.io/company/${comp}`}
                            >
                              {(link) => (
                                <a href={link} target="_blank">
                                  <MdOutlineShare />
                                </a>
                              )}
                            </ShareLink>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
            </table>
          </div>
          {/* {roles.map((role) => {
          return (
            //table starts

            //table ends

            <div className="flex justify-center ">
              <div
                id={role.title}
                className="block p-2 border-2 rounded-lg shadow-lg bg-white max-w-lg cursor-pointer m-4 w-3/4 text-left"
                onClick={(e) => {
                  setClickedRole(e.target.id);
                }}
              >
                <h5
                  className="text-gray-900 text-xl leading-tight font-medium mb-2"
                  id={role.title}
                >
                  Title: {role.title}
                </h5>
                <p className="text-gray-700 text-base mb-4" id={role.title}>
                  Department: {role.department}
                </p>
              </div>
            </div>
          );
        })} */}
        </div>
        <div>
          {modal ? (
            <div>
              {/* Modal  */}

              <div
                className="modal rounded-md shadow-black fade fixed top-40 left-1/4 bg-white shadow-lg  w-1/2 justify-self-center  outline-none overflow-auto h-3/5"
                id={filteredRole.title}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog relative w-auto pointer-events-none">
                  <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                      <h5
                        className="text-xl font-medium leading-normal text-gray-800"
                        id="exampleModalLabel"
                      >
                        {filteredRole.title}
                      </h5>
                      <div>
                        <button
                          type="button"
                          className="px-6
                            py-2.5
                            bg-purple-600
                            text-white
                            font-medium
                            text-xs
                            leading-tight
                            uppercase
                            rounded
                            shadow-md
                            hover:bg-purple-700 hover:shadow-lg
                            focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0
                            active:bg-purple-800 active:shadow-lg
                            transition
                            duration-150
                            ease-in-out"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setModal(false);
                            setClickedRole("");
                            setPopUp(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                      {/* <div>
                      <button
                        type="button"
                        id={filteredRole.title}
                        className="px-6
                      py-2.5
                      bg-yellow-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-yellow-700 hover:shadow-lg
                      focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-yellow-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          updateArchive(e.target.id);
                        }}
                      >
                        Archive
                      </button>

                      <button
                        type="button"
                        id={filteredRole.title}
                        className="px-6
                      py-2.5
                      bg-red-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-red-700 hover:shadow-lg
                      focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-red-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          setTitleToDelete(e.target.id);
                          setPopUp(true);
                        }}
                      >
                        Delete
                      </button>
                    </div> */}
                    </div>
                    <div className="modal-body relative p-4">
                      <span className="font-semibold">Department:</span>{" "}
                      {filteredRole.department}
                    </div>
                    <div className="modal-body relative p-4">
                      <span className="font-semibold">Type:</span>{" "}
                      {filteredRole.type}
                    </div>
                    <div className="modal-body relative p-4">
                      <span className="font-semibold">Location:</span>{" "}
                      {filteredRole.location}
                    </div>
                    <p className="modal-body relative p-4">
                      <span className="font-semibold">About the role:</span>{" "}
                      {filteredRole.about}
                    </p>
                    <div className="modal-body relative p-4">
                      <span className="font-semibold">What you'll do:</span>{" "}
                      {filteredRole.willDo}
                    </div>
                    <div className="modal-body relative p-4">
                      <span className="font-semibold">Must have:</span>{" "}
                      {filteredRole.mustHave}
                    </div>
                    <div className="modal-body relative p-4">
                      <span className="font-semibold">
                        Additional requirements:
                      </span>{" "}
                      {filteredRole.hobbies}
                    </div>
                    {/* <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                    <button
                      type="button"
                      className="px-6
                            py-2.5
                            bg-purple-600
                            text-white
                            font-medium
                            text-xs
                            leading-tight
                            uppercase
                            rounded
                            shadow-md
                            hover:bg-purple-700 hover:shadow-lg
                            focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0
                            active:bg-purple-800 active:shadow-lg
                            transition
                            duration-150
                            ease-in-out"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setModal(false);
                        setClickedRole("");
                        setPopUp(false);
                      }}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="px-6
                    py-2.5
                    bg-blue-600
                    text-white
                    font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-blue-700 hover:shadow-lg
                    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-blue-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out
                    ml-1"
                      onClick={() => {
                        // props.setCandidate(filteredRole[0].title);
                        props.onRoleSelect(filteredRole.title);
                      }}
                    >
                      Show Details
                    </button>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {popUp ? (
          <div>
            <div className=" fixed left-2/4 top-52 ">
              <div className="block p-6 rounded-lg shadow-lg  bg-slate-100 max-w-sm">
                <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                  Are you sure?
                </h5>
                {/* <p className="text-gray-700 text-base mb-4">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p> */}
                <button
                  type="button"
                  className=" inline-block px-6 mr-2 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => deleteRole(titleToDelete)}
                >
                  confirm
                </button>
                <button
                  type="button"
                  className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => {
                    setPopUp(false);
                    setTitleToDelete("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Roles;
