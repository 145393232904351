import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { app, db, storage } from "../firebase/Config";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import validator from "validator";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CompanyPage() {
  const [roles, setRoles] = useState([]);
  const [modal, setModal] = useState(null);
  const [apply, setApply] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [companyDetail, setCompanyDetail] = useState("");
  const [logoUrl, setLogoUrl] = useState(null);
  const [linkedin, setLinkedin] = useState("");
  const [portfolio, setPortfolio] = useState("");

  const [loading, setLoading] = useState(true);
  const [todaysDate, setTodaysDate] = useState();

  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");

  const getTodaysDate = () => {
    let currentDate = new Date();
    let cDay = currentDate.getDate();
    let cMonth = currentDate.getMonth() + 1;
    let cYear = currentDate.getFullYear();
    setTodaysDate(cDay + "-" + cMonth + "-" + cYear);
  };

  const params = useParams();
  // console.log(params);
  const id = params.id;

  const readCompanyDetails = async () => {
    const q = query(collection(db, "company"), where("name", "==", id));

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setCompanyDetail(doc.data().about);
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const readData = async () => {
    const arr = [];

    const q = query(
      collection(db, "roles"),
      where("company", "==", id),
      where("archive", "==", false)
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // console.log(doc.data());
        // console.log(`${doc.id} => ${doc.data()}`);
        // setCandidate((candidate) => [...candidate, doc.data()]);
        arr.push(doc.data());
        // console.log(arr);
      }, setLoading(false));

      // console.log(arr);
      setRoles(arr);
      // console.log(roles);

      // console.log(roles);
    } catch (e) {
      console.log("error", e);
    }
  };

  const applyForRole = async () => {
    try {
      if (
        !validator.isEmpty(name) &&
        validator.isEmail(email) &&
        file !== null
      ) {
        await addDoc(collection(db, "candidates"), {
          name: name,
          email: email,
          linkedin: linkedin,
          portfolio: portfolio,
          company: company,
          role: selectedRole,
          status: "applied",
          userId: roles[0].uid,
          date: todaysDate,
        });
        // console.log("Document written with ID: ", docRef.id);
        resumeUpload(file, email);
        triggerEmail(email);
        // window.alert("Applied Successfully");
        setName("");
        setEmail("");
        setFile(null);
        setLinkedin("");
        setPortfolio("");
      } else if (validator.isEmail(email) === false) {
        alertToastEmail();
        // setName("");
        // setEmail("");
        // setFile(null);
      } else if (file === null) {
        alertToastFileType();
      } else {
        alertToast();
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const resumeUpload = (file, email) => {
    const storage = getStorage();

    // Create the file metadata
    // /** @type {any} */
    // const metadata = {
    //   contentType: "image/jpeg",
    // };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, "resume/" + email + ".pdf");
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progres = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progres + "% done");
        setProgress(progres);

        if (progres === 100) {
          setProgress(progres);
          setApply(null);
          successToast();
        }
        // console.log("completion", snapshot.state);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");

            break;
        }
      },
      // setProgress(progres),
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }
      // setProgress("98"),
      // setApply(null)
      // () => {
      //   // Upload completed successfully, now we can get the download URL
      //   getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      //     console.log("File available at", downloadURL);
      //   });
      // }
    );

    // window.alert("Applied Successfully");
  };

  const downloadLogo = () => {
    // Create a reference to the file we want to download
    const storage = getStorage();
    const starsRef = ref(storage, "logo/" + id + ".jpg");

    // Get the download URL
    getDownloadURL(starsRef)
      .then((url) => {
        // Insert url into an <img> tag to "download"
        // console.log(url);
        setLogoUrl(url);
        // This can be downloaded directly:
        // const xhr = new XMLHttpRequest();
        // xhr.responseType = "blob";
        // xhr.onload = (event) => {
        //   const blob = xhr.response;
        // };
        // xhr.open("GET", url);
        // xhr.send();
        // window.open(url);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  useEffect(() => {
    readData();
    readCompanyDetails();
    downloadLogo();
    getTodaysDate();
  }, []);

  useEffect(() => {
    document.title = `${id} jobs`;
  }, []);

  const changeHandlerForFile = (f) => {
    fileValidation(f);
  };

  //function for checking file type
  const fileValidation = (uploadedFile) => {
    ///(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd)$/i;

    var fileType = uploadedFile.type;

    // console.log(fileType);

    if (fileType === "application/pdf") {
      // console.log("correct file");
      setFile(uploadedFile);
    } else {
      // console.log("not correct file");
      alertToastFileType();
    }
    // Allowing file type
    // var allowedExtensions = /(\. )

    // if (!allowedExtensions.exec(filePath)) {
    //     alert('Invalid file type');
    //     fileInput.value = '';
    //     return false;
    // }
  };

  //Email Trigger
  const triggerEmail = async (to) => {
    try {
      const docRef = await addDoc(collection(db, "mail"), {
        to: to,

        from: `${company.toUpperCase()} Hiring Team <hello@solidhire.io>`,
        message: {
          subject: "Thanks for applying to " + company,
          text: `
Hey ${name}
          
Thanks for applying to ${company}. We've got your application and will review it soon!

We will get back to you shortly, please bear with us while we process your application.
          
Regards,
          
Team ${company}
          
** Please note: Do not reply to this email. This email is sent from an unattended mailbox. Replies will not be read.`,
        },
      });
      // console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.log(e);
    }
  };

  const successToast = () => toast.success("Applied Successfully!");
  const alertToast = () => toast.warn("Please fill all details!");
  const alertToastEmail = () => toast.warn("Please enter valid email address!");
  const alertToastFileType = () => toast.warn("Please upload PDF file only!");

  const departments = roles.map((role) => role.department.trim());
  const uniqueDepartments = [...new Set(departments)];

  const locations = roles.map((role) => role.location.trim());
  const uniqueLocations = [...new Set(locations)];

  const types = roles.map((role) => role.type.trim());
  const uniqueTypes = [...new Set(types)];

  return (
    <div>
      {loading ? (
        <div className="relative">
          <div className="fixed   top-52 left-0 right-0 text-center">
            <svg
              role="status"
              className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      ) : null}
      {!loading ? (
        <div className="pb-5">
          {setRoles === [] ? (
            <div>No Roles</div>
          ) : (
            <>
              <div className="flex justify-center p-3 mx-5 underline-offset-2  border-b-2 lg:mx-10 lg:p-2 ">
                <img className="h-10 w-auto" src={logoUrl}></img>
              </div>
              <div className=" flex justify-center p-6">
                {/* <img src={logoUrl}></img> */}
                <div className=" flex-col justify-center lg:w-1/2 items-center     lg:m-5">
                  <div className="font-semibold">{id.toUpperCase()}</div>
                  <div className="lg:text-base text-sm">{companyDetail}</div>
                </div>
              </div>

              <div className=" flex justify-center pl-4">
                {/* <img src={logoUrl}></img> */}
                <div className=" flex-col justify-center lg:w-1/2 items-center lg:m-1">
                  <div className="font-semibold text-lg">
                    Open Positions ({roles.length})
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-2 mb-2 w-full">
                <div className="md:flex lg:flex   justify-between p-6 rounded-lg shadow-lg bg-white lg:w-1/2 w-full mx-5">
                  {/* dopdown for department */}
                  {/* {set first option as all department} */}
                  <select
                    className="border-2 m-2 w-full border-gray-300 bg-white h-10 pl-2 pr-2 rounded-lg text-sm focus:outline-none"
                    onChange={(e) => setDepartment(e.target.value)}
                  >
                    <option value="all">All Departments</option>
                    {uniqueDepartments.map((role, i) => {
                      return (
                        <option key={i} value={role}>
                          {role}
                        </option>
                      );
                    })}
                  </select>

                  <select
                    className="border-2 m-2 w-full border-gray-300 bg-white h-10 pl-2 pr-2 rounded-lg text-sm focus:outline-none"
                    onChange={(e) => setLocation(e.target.value)}
                  >
                    <option value="all">All Locations</option>
                    {uniqueLocations.map((role, i) => {
                      return (
                        <option key={i} value={role}>
                          {role}
                        </option>
                      );
                    })}
                  </select>

                  <select
                    className="border-2 m-2 w-full border-gray-300 bg-white h-10 pl-2 pr-2 rounded-lg text-sm focus:outline-none"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="all">All Employment Types</option>
                    {uniqueTypes.map((role, i) => {
                      return (
                        <option key={i} value={role}>
                          {role}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {roles
                .filter((roles) => {
                  if (department === "all") {
                    return roles;
                  } else if (
                    roles.department
                      .toLowerCase()
                      .includes(department.toLowerCase())
                  ) {
                    return roles;
                  }
                })
                .filter((roles) => {
                  if (location === "all") {
                    return roles;
                  } else if (
                    roles.location
                      .toLowerCase()
                      .includes(location.toLowerCase())
                  ) {
                    return roles;
                  }
                })
                .filter((roles) => {
                  if (type === "all") {
                    return roles;
                  } else if (
                    roles.type.toLowerCase().includes(type.toLowerCase())
                  ) {
                    return roles;
                  }
                })
                .map((role, i) => {
                  return (
                    <div
                      className="flex justify-center mt-2 mb-2 w-full"
                      key={i}
                    >
                      <div
                        className=" p-6 rounded-lg shadow-lg bg-white lg:w-1/2 w-full mx-5"
                        id={role.company}
                      >
                        <h5 className="text-gray-900 text-xl leading-tight font-bold mb-2">
                          {role.title}
                        </h5>
                        <p className="text-gray-700 text-base mb-1">
                          <span>{role.department}</span> -{" "}
                          <span>{role.location}</span> -<span>{role.type}</span>
                        </p>

                        {modal == i ? (
                          <>
                            <p>---------------------------</p>
                            <p className="text-gray-700  mb-4 lg:text-base text-sm">
                              <h1 className="text-md font-bold">
                                About the Role:
                              </h1>
                              {role.about}
                            </p>
                            <p className="text-gray-700 lg:text-base text-sm mb-4">
                              <h1 className="text-md font-bold">
                                What you'll do:
                              </h1>
                              {role.willDo}
                            </p>
                            <p className="text-gray-700 lg:text-base text-sm mb-4">
                              <h1 className="text-md font-bold">You have:</h1>
                              {role.mustHave}
                            </p>
                            <p className="text-gray-700 lg:text-base text-sm mb-4">
                              <h1 className="text-md font-bold">You are:</h1>
                              {role.hobbies}
                            </p>
                            <button
                              type="button"
                              className=" inline-block px-6 mr-2 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                              onClick={() => {
                                setApply(i);
                                setCompany(role.company);
                                setSelectedRole(role.title);
                                // console.log("company", company);
                                // console.log("selectedRole", selectedRole);
                                setProgress(0);
                                // console.log(progress);
                              }}
                            >
                              Apply
                            </button>
                          </>
                        ) : null}
                        <button
                          type="button"
                          className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                          onClick={() =>
                            modal == i ? setModal(null) : setModal(i)
                          }
                        >
                          {modal == i ? "Show Less" : "Show More"}
                        </button>
                      </div>
                      {apply == i ? (
                        <div className="fixed top-20 z-10 p-6 rounded-lg shadow-2xl bg-white lg:w-2/4">
                          <div className="form-group mb-6">
                            <input
                              type="text"
                              className="form-control block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              id="exampleInput7"
                              placeholder="Name*"
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-6">
                            <input
                              type="email"
                              className="form-control block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              id="exampleInput8"
                              placeholder="Email address*"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-6">
                            <input
                              type="Link"
                              className="form-control block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              id="exampleInput8"
                              placeholder="LinkedIn Profile Link*"
                              onChange={(e) => setLinkedin(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-6">
                            <input
                              type="Link"
                              className="form-control block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              id="exampleInput8"
                              placeholder="Portfolio/Personal Website"
                              onChange={(e) => setPortfolio(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-6">
                            Upload Resume
                            <input
                              className="
                          form-control
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                              id="file-input"
                              rows="3"
                              type="file"
                              placeholder="attach your resume"
                              onChange={(e) =>
                                changeHandlerForFile(e.target.files[0])
                              }
                            ></input>
                            <div className="ml-3 text-gray-700 text-xs">
                              Upload PDF files only.
                            </div>
                          </div>

                          <div>
                            <div className="bg-white rounded-lg mb-2 shadow block p-4 m-auto">
                              <div>
                                {progress === 0 ? null : (
                                  <div className="flex justify-start items-center">
                                    <svg
                                      role="status"
                                      className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                    <div>{Math.round(progress)}% uploaded</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="
                  w-full
                  px-6
                  py-2.5
                  bg-blue-600
                  text-white
                  font-medium
                  text-xs
                  leading-tight
                  uppercase
                  rounded
                  shadow-md
                  hover:bg-blue-700 hover:shadow-lg
                  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                  active:bg-blue-800 active:shadow-lg
                  transition
                  duration-150
                  ease-in-out"
                            onClick={() => {
                              applyForRole();

                              // if (progress === 100) {
                              //   setApply(null);
                              // }
                            }}
                          >
                            Apply
                          </button>
                          <button
                            type="submit"
                            className="
                  w-full
                  px-6
                  py-2.5
                  bg-blue-600
                  text-white
                  font-medium
                  text-xs
                  leading-tight
                  uppercase
                  rounded
                  shadow-md
                  hover:bg-blue-700 hover:shadow-lg
                  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                  active:bg-blue-800 active:shadow-lg
                  transition
                  duration-150
                  ease-in-out
                  mt-2"
                            onClick={() => {
                              setApply(null);
                              setEmail("");
                              setName("");
                              setFile(null);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              <div className="fixed bottom-0 mx-10">
                Powered by{" "}
                <span className="font-semibold hover:font-bold ">
                  <a href="https://www.solidhire.io" target="_blank">
                    solidHire
                  </a>
                </span>
              </div>
            </>
          )}
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
}

export default CompanyPage;
