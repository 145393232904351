import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  doc,
  deleteDoc,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase/Config";
import validator from "validator";
import { async } from "@firebase/util";

function Profile() {
  const [popup, setPopup] = useState(false);
  const [userId, setUserId] = useState("");
  const [company, setCompany] = useState("");
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [secUID, setSecUID] = useState("");
  var [usersArray, setUsersArray] = useState([]);
  var [newUsersArray, setNewUsersArray] = useState([]);
  const [deleteUid, setDeleteUid] = useState("");
  const [mainUid, setMainUid] = useState("");

  const authStatus = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;

        // console.log("uid", uid);
        setUserId(uid);
        // setUsersArray([uid]);

        // console.log(uid);

        // ...
      } else {
        console.log("Error! in AuthStatus");

        // User is signed out
        // ...
      }
    });
  };

  const getCompany = async () => {
    const q = query(collection(db, "company"), where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setCompany(doc.data().name);
      //   console.log("company", doc.data());
    });
  };

  const uidData = async () => {
    const docs = [];
    const arr = [];
    const q = query(
      collection(db, "secondaryUsers"),
      where("mainUID", "==", userId),
      where("company", "==", company)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log(doc.data());
      // console.log(`${doc.id} => ${doc.data()}`);
      // setCandidate((candidate) => [...candidate, doc.data()]);
      arr.push(doc.data());
      docs.push(doc.id);
      // console.log(arr);
    });
    // console.log(querySnapshot);
    // console.log(arr);
    // console.log("sorted", arr);
    // setDocId(docs);
    setData(arr);
    // console.log(arr);
    // setStatusBox(null);
  };

  useEffect(() => {
    authStatus();
    getCompany();
    uidData();
  }, [userId, company]);

  useEffect(() => {
    usersArray.length < newUsersArray.length && onSubmit();
    usersArray.length < newUsersArray.length && updateRoles();
  }, [usersArray, newUsersArray]);

  const onSubmit = async () => {
    // console.log("secUID", secUID);
    // console.log("inside onsubmit", usersArray, typeof usersArray);
    // // setUsersArray([...usersArray, secUID]);
    // console.log("After inside onsubmit", usersArray, typeof usersArray);

    if (
      !validator.isEmpty(name) &&
      !validator.isEmpty(email) &&
      !validator.isEmpty(secUID)
    ) {
      await addDoc(
        collection(db, "secondaryUsers"),
        {
          company: company,
          email: email,
          mainUID: userId,
          name: name,
          secondaryUID: secUID,
        }
        // setUsersArray([...usersArray], secUID),
        // console.log("inside onsubmit", usersArray)
      )
        .then(() => {
          //   console.log(usersArray);

          resetStates();
          window.alert("User Added!");
          uidData();
          updateCandidates();
        })
        .catch((error) => console.log(error));
    } else {
      window.alert("fill all details please!");
    }
  };

  const resetStates = () => {
    setName("");
    setEmail("");
    setSecUID("");
  };

  useEffect(() => {
    newUsersArray.length < usersArray.length &&
      deleteUid &&
      deleteUser(deleteUid);
  }, [newUsersArray, usersArray, deleteUid]);

  const deleteUser = async (u) => {
    // console.log("deleteUID", deleteUid);
    const q = query(
      collection(db, "secondaryUsers"),
      where("secondaryUID", "==", u)
    );

    try {
      const querySnapshot = await getDocs(q);
      const id = querySnapshot.docs[0].id;
      await deleteDoc(doc(db, "secondaryUsers", id));

      //updating the users
      updateCandidates();

      //updating Roles
      updateRoles();

      console.log("doc deleted");

      uidData();
    } catch (e) {
      console.log(e.message);
    }
  };

  const updateCandidates = async () => {
    const arr = [];
    // console.log("userArray", usersArray);

    const q = query(
      collection(db, "candidates"),
      where("userId", "array-contains", userId),
      //   where("userId", "==", userId),
      where("company", "==", company)
    );

    try {
      //   console.log("inside update", usersArray);
      const querySnapshot = await getDocs(q);
      //   console.log("QS", querySnapshot);
      querySnapshot.forEach((doc) => {
        // console.log(doc.id);
        // console.log(`${doc.id} => ${doc.data()}`);
        // setCandidate((candidate) => [...candidate, doc.data()]);
        // doc.ref.update({
        //   userId: usersArray,
        // });

        // const ref = doc(db, "candidates", doc.id);

        // await updateDoc(ref, {
        //   userId: usersArray,
        // });

        updateUserData(doc.id);

        // console.log(arr);
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const updateRoles = async () => {
    //for updating roles
    const t = query(collection(db, "roles"), where("company", "==", company));

    try {
      //   console.log("inside update", usersArray);
      const querySnapshot = await getDocs(t);
      //   console.log("QS", querySnapshot);
      querySnapshot.forEach((doc) => {
        // console.log(doc.id);
        // console.log(`${doc.id} => ${doc.data()}`);
        // setCandidate((candidate) => [...candidate, doc.data()]);
        // doc.ref.update({
        //   userId: usersArray,
        // });

        // const ref = doc(db, "candidates", doc.id);

        // await updateDoc(ref, {
        //   userId: usersArray,
        // });

        updateRolesData(doc.id);

        // console.log(arr);
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const updateRolesData = async (i) => {
    // console.log("inside updateUserData", i, usersArray);
    const ref = doc(db, "roles", i);
    // console.log("updateRolesdata", [...newUsersArray]);

    await updateDoc(ref, {
      uid: [...newUsersArray],
    });

    getuidData();
  };

  const updateUserData = async (i) => {
    // console.log("inside updateUserData", i, usersArray);
    const ref = doc(db, "candidates", i);

    await updateDoc(ref, {
      userId: [...newUsersArray],
    });

    getuidData();
  };

  //   const getuidData = async () => {
  //     const q = query(
  //       collection(db, "candidates"),
  //       where("userId", "array-contains", userId)
  //     );
  //     console.log("inside getUidData");
  //     const querySnapshot = await getDocs(q);
  //     // querySnapshot.forEach((doc) => {
  //     //   //   setCompany(doc.data().name);
  //     //   console.log("uidData", doc.data().userId);
  //     // });
  //     console.log(querySnapshot.docs[0].data().userId);
  //     setUsersArray(querySnapshot.docs[0].data().userId);
  //     console.log("getuidDatawala2", usersArray);
  //   };

  const getuidData = async () => {
    const q = query(
      collection(db, "roles"),
      where("uid", "array-contains", userId)
    );
    // console.log("inside getUidData");
    const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   //   setCompany(doc.data().name);
    //   console.log("uidData", doc.data().userId);
    // });
    // console.log("getUID data wala", querySnapshot.docs[0].data().uid);
    setUsersArray(querySnapshot.docs[0].data().uid);
    // console.log("getuidDatawala2", usersArray);
  };

  useEffect(() => {
    userId && getuidData();

    // console.log("getuidDaawala", usersArray);
  }, [userId]);

  return (
    <div>
      {popup ? (
        <div className="flex justify-center mt-20">
          <div className="fixed ">
            <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
              <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                Your UID
              </h5>
              <p className="text-gray-700 text-base mb-4">{userId}</p>
              <div>
                Please do not share uid with unknown people. It might cause
                unexpected security issues.
              </div>
              <button
                type="button"
                className="mt-2 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={() => setPopup(!popup)}
              >
                close
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-col justify-center items-center m-16">
        <div>Profile Details</div>
        {company ? <div>Account: Admin</div> : null}
        <div className="m-6">
          <button
            type="button"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={() => setPopup(true)}
          >
            Generate UID
          </button>
        </div>

        {company ? (
          <div>
            <div className="ml-6">Add Secondary Users</div>
            <div className="flex justify-center w-full m-2">
              <table className="table-auto w-1/2 rounded-lg ">
                <thead className="rounded-md border-separate 	">
                  <tr className="bg-gray-800 text-left rounded-md border-separate	">
                    <th className="border border-black p-2 text-white">Name</th>
                    <th className="border border-black p-2 text-white">
                      Email
                    </th>
                    <th className="border border-black p-2 text-white">UID</th>
                    <th className="border border-black p-2 text-white"></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, i) => {
                    return (
                      <tr id={d.secondaryUID}>
                        <td className="border-2 pl-2">{d.name}</td>
                        <td className="border-2 pl-2">{d.email}</td>
                        <td className="border-2 pl-2">{d.secondaryUID}</td>
                        <td className=" border-2" id={d.secondaryUID}>
                          <button
                            id={d.secondaryUID}
                            onClick={(e) => {
                              setDeleteUid(e.target.id);
                              const ind = usersArray.findIndex(
                                (v) => v == e.target.id
                              );

                              //   console.log("v", ind);
                              console.log("userArray", usersArray);
                              //   const newArray = usersArray.splice(ind, 1);
                              const newArray = usersArray.filter(
                                (x) => x != e.target.id
                              );
                              console.log("newArray", newArray);

                              setNewUsersArray(newArray);
                              //   setNewUsersArray(
                              //     newUsersArray.filter(
                              //       (x) => x.target.id !== e.target.id
                              //     )
                              //   );
                              //   console.log(
                              //     "inside remove button",
                              //     newUsersArray
                              //   );

                              //   console.log("xx", deleteUid);
                              //   console.log("xx", newUsersArray);
                            }}
                            className="m-1 inline-block px-3 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className="border-2 pl-2">
                      <input
                        className="w-40"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      ></input>
                    </td>
                    <td className="border-2 pl-2">
                      <input
                        className="w-40"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      ></input>
                    </td>
                    <td className="border-2 pl-2">
                      <input
                        className="w-40"
                        placeholder="UID"
                        onChange={(e) => setSecUID(e.target.value.trim())}
                        value={secUID}
                      ></input>
                    </td>
                    <td className=" border-2">
                      <button
                        onClick={() => {
                          setNewUsersArray([...usersArray, secUID]);
                        }}
                        className="w-11/12 m-1  inline-block px-3 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        <div className="w-2/5 flex-col justify-center  mt-2 ">
          <div className="font-bold">How to give access to other Users?</div>
          <div>
            <div>1. Ask new user to sign-up into app.Solidhire.io </div>
            <div>2. New user must not add any details after sign-up.</div>
            <div>
              3. New user must go to Teams tab right after he/she is signed-in.
            </div>
            <div>
              4. New user must click on "GENERATE UID" button which will produce
              a pop that has UID code.
            </div>
            <div>
              5. New user must copy this code carefully and give it to the
              Admin.
            </div>
            <div>
              6. Admin user must go to profile section and add Name, Email and
              UID provided by the new user under "Add secondary Users" section.
            </div>
            <div>
              7. Thats it, Now new user must be able to access the data.
            </div>
            <div>
              8. If you face any issue, please contact customer service.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
