import React from "react";
import { app } from "../firebase/Config";
import { signInWithG } from "../firebase/SignInWithGoogle";
import { signOutG } from "../firebase/SignOut";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function () {
  const [userName, setUserName] = useState("");
  const [status, setStatus] = useState(false);
  const [drop, setDrop] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const authStatus = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUserName(user.displayName);
        setAvatar(user.photoURL);

        setStatus(true);
      } else {
        console.log("Error! in AuthStatus");
        setStatus(false);
        setUserName("");
      }
    });
  };

  authStatus();

  return (
    <div className="z-20">
      <div
        className="flex justify-around items-center py-4 bg-white/80
            backdrop-blur-md shadow-md w-full
              z-10"
      >
        {!status ? (
          <div>
            <Link className="flex" to="/">
              <img src="https://uploads-ssl.webflow.com/6258043e3e11f23b112b4578/6258043f3e11f283d72b45b6_32favicon.png" />
              <h1 className="text-xl font-bold">SolidHire</h1>
            </Link>
          </div>
        ) : (
          <div></div>
        )}
        <div className="flex ">
          {/* <div className="m-2">
            <h1>{userName}</h1>
          </div> */}

          <div className="flex align-middle items-center">
            {status ? (
              <div>
                <Link
                  className="p-1 mr-1 font-medium hover:font-bold hidden"
                  to="/"
                >
                  Roles
                </Link>
                <Link
                  className="p-1 mr-1 font-medium hover:font-bold hidden"
                  to="/introduction"
                >
                  Add Openings
                </Link>
                <button
                  className="p-1 mr-1 font-medium hover:font-bold hidden"
                  onClick={signOutG}
                >
                  Sign Out
                </button>

                <span className="flex items-center">
                  <span>{`Hello ${userName}!`}</span>
                  {avatar ? (
                    <img
                      alt="profile"
                      src={avatar}
                      className="rounded-full h-8 w-8 ml-2 "
                    />
                  ) : (
                    <svg
                      className="rounded-full h-8 w-8 ml-2 "
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
