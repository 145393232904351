import React, { useMemo, useState, useEffect } from "react";
import Navbar from "./Navbar";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  doc,
  orderBy,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase/Config";

import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CSVLink } from "react-csv";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import moment from "moment";
import { MdSettings, MdOutlineInfo, MdClear } from "react-icons/md";

import fuzzysearch from "fuzzysearch";

function HomePage(props) {
  const [candidate, setCandidate] = useState([]);
  const [modal, setModal] = useState(false);
  const [uid, setUid] = useState("");
  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");
  const [docId, setDocId] = useState([]);

  const [popUp, setPopUp] = useState(false);
  const [targetId, setTargetId] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [statusBox, setStatusBox] = useState(null);
  const [defaultCandidate, setDefaultCandidate] = useState([]);
  const [paginate, setPaginate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [filter, setFilter] = useState("");
  const [sortStatus, setSortStatus] = useState(true);
  const [addNotes, setAddNotes] = useState(false);
  const [todaysDate, setTodaysDate] = useState();
  const [notesString, setNotesString] = useState("");

  const [notesDataArray, setNotesDataArray] = useState([]);
  const [notesDataArray2, setNotesDataArray2] = useState([]);
  const [nameCandidate, setNameCandidate] = useState("");

  const [AdminTriggerEmail, setAdminTriggerEmail] = useState("");
  const [toTriggerEmail, setToTriggerEmail] = useState("");

  const [ccEmail, setCcEmail] = useState("");
  const [sendEmailToggle, setSendEmailToggle] = useState(null);
  const [emailBody, setEmailBody] = useState(null);
  const [emailSubjectError, setEmailSubjectError] = useState(false);
  const [emailBodyError, setEmailBodyError] = useState(false);

  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [settingsDropDown, setSettingsDropDown] = useState(false);
  const [transferRole, setTransferRole] = useState("");
  const [transferRolePopUp, setTransferRolePopUp] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [transferRoleError, setTransferRoleError] = useState(false);
  const [search, setSearch] = useState("");

  const getTodaysDate = () => {
    let currentDate = new Date();
    let cDay = currentDate.getDate();
    let cMonth = currentDate.getMonth() + 1;
    let cYear = currentDate.getFullYear();
    setTodaysDate(cDay + "-" + cMonth + "-" + cYear);
  };

  const params = useParams();

  const getUser = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user !== null) {
      setUid(user.uid);
      setAdminTriggerEmail(user.email);
    } else {
      console.log("user is not signed in");
    }
  };

  const getCompany = async () => {
    const q = query(collection(db, "company"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setCompany(doc.data().name);
    });
  };

  const getEmailToggle = async () => {
    const q = query(collection(db, "company"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log(doc.data());

      if (!!doc.data().emailToggle === true) {
        doc.data().emailToggle === true
          ? setSendEmailToggle(true)
          : setSendEmailToggle(false);
        // console.log("insdie doc.data if statement");
      } else {
        console.log("no email toggle");
        // setSendEmailToggle(true);
      }
    });
    // console.log("sendemailtraigger", sendEmailToggle);
  };

  const changeEmailToggle = async () => {
    const q = query(collection(db, "company"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      updateDoc(doc.ref, {
        emailToggle: sendEmailToggle,
      });
    });
  };

  useEffect(() => {
    changeEmailToggle();
  }, [sendEmailToggle]);

  useEffect(() => {
    getEmailToggle();
    getEmailBodyData();
  }, []);

  useEffect(() => {
    setEmailBodyData();
  }, [emailBody]);

  const getEmailBodyData = async () => {
    const q = query(collection(db, "company"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (!!doc.data().emailBody === true) {
        setEmailBody(doc.data().emailBody);
      } else {
        // console.log("no email body");
        setEmailBody({
          applied: { subject: "", body: "" },
          rejected: { subject: "", body: "" },
          shortlisted: { subject: "", body: "" },
          interviewed: { subject: "", body: "" },
          "interviewed-2": { subject: "", body: "" },
          "interviewed-3": { subject: "", body: "" },
          selected: { subject: "", body: "" },
        });
      }
    });
  };

  const setEmailBodyData = async () => {
    const q = query(collection(db, "company"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      updateDoc(doc.ref, {
        emailBody: emailBody,
      });
    });
  };

  // const updateStatus = async (id, s) => {
  //   const candidateRef = doc(db, "candidates", id);

  //   // Set the "status" field of the candidate 'id'
  //   await updateDoc(candidateRef, {
  //     status: s,
  //   });
  //   setPopUp(false);
  //   readData();
  // };

  const readData = async () => {
    var arr = [];
    var arr1 = [];
    var arr2 = [];

    var q;

    if (filter === "applied") {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role),
        where("status", "==", "applied")
      );
    } else if (filter === "shortlisted") {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role),
        where("status", "==", "shortlisted")
      );
    } else if (filter === "interviewed") {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role),
        where("status", "==", "interviewed")
      );
    } else if (filter === "interviewed-2") {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role),
        where("status", "==", "interviewed-2")
      );
    } else if (filter === "interviewed-3") {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role),
        where("status", "==", "interviewed-3")
      );
    } else if (filter === "selected") {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role),
        where("status", "==", "selected")
      );
    } else if (filter === "rejected") {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role),
        where("status", "==", "rejected")
      );
    } else if (filter === "sort") {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role),
        orderBy("status", "desc")
      );
    } else {
      q = query(
        collection(db, "candidates"),
        where("userId", "array-contains", uid),
        // where("company", "==", company),
        where("role", "==", role)
      );
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push({ data: doc.data(), id: doc.id });
    });

    arr.map((t) => {
      if (t.data.date) {
        const [dd, mm, yyyy] = t.data.date.split("-");
        const newD = [yyyy, mm, dd].join("-");
        t.data.date = new Date(newD);
      } else {
        t.data.date = new Date("2022-01-01");
      }
      arr1.push(t);
    });

    arr2 = arr1.sort((a, b) => {
      return b.data.date - a.data.date;
    });

    setCandidate(arr2);

    dataForCsv(arr2);
    setLoading(false);
    setStatusBox(null);

    if (filter === "") {
      setDefaultCandidate(arr2);
    }
  };

  const getAllRoles = async () => {
    var arr = [];
    var q = query(
      collection(db, "roles"),

      where("uid", "array-contains", uid),
      where("archive", "==", false)
    );
    await getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      setAllRoles(arr);
    });

    // console.log("all roles", arr);
  };

  // useEffect(() => {
  //   getAllRoles();
  // }, []);

  const transferCandidatesToSelectedRole = async () => {
    try {
      selectedCandidates.map((item) => {
        updateDoc(doc(db, "candidates", item.id), {
          role: transferRole,
          transferredFrom: item.data.role,
        });
      });
      successfulTransferToast();
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (id, s) => {
    const candidateRef = doc(db, "candidates", id);

    // Set the "status" field of the candidate 'id'
    await updateDoc(candidateRef, {
      status: s,
    });
    setPopUp(false);
    readData();
  };

  //get Doc details for emailing
  const emailData = async (id) => {
    const docRef = doc(db, "candidates", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data().email);
      setToTriggerEmail(docSnap.data().email);
      triggerEmail(docSnap.data().email, AdminTriggerEmail);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const updateNotes = async (id, s) => {
    const candidateRef = doc(db, "candidates", id);

    await updateDoc(
      candidateRef,
      {
        notesData: notesDataArray2,
      },
      setNotesDataArray(notesDataArray2)
    );

    // Set the "status" field of the candidate 'id'

    readData();
    setNotesString("");
  };

  useEffect(() => {
    targetId && updateNotes(targetId, targetValue);
  }, [notesDataArray2]);

  const downloadResume = (e) => {
    // Create a reference to the file we want to download
    const storage = getStorage();
    const starsRef = ref(storage, "resume/" + e + ".pdf");

    // Get the download URL
    getDownloadURL(starsRef)
      .then((url) => {
        window.open(url);
      })
      .catch((error) => {
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't
            console.log("File doesn't exist");
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            console.log("User doesn't have permission to access the object");
            break;
          case "storage/canceled":
            // User canceled the upload
            console.log("User canceled the upload");
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            console.log("Unknown error occurred, inspect the server response");
            break;
        }
      });
  };

  //resume link
  const resumeLink = (e) => {
    // Create a reference to the file we want to download
    var link;

    const storage = getStorage();
    const starsRef = ref(storage, "resume/" + e + ".pdf");
    // Get the download URL
    return getDownloadURL(starsRef)
      .then((url) => {
        //set link as url

        link = url;

        return link;
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  const [t, setT] = useState([]);

  //   const triggerEmail = async (to, cc) => {
  //     try {
  //       const docRef = await addDoc(collection(db, "mail"), {
  //         to: to,
  //         cc: cc,
  //         from: "hello@solidhire.io",
  //         message: {
  //           subject: "Shortlisted for " + role + " " + "at " + company,
  //           text: `Hello ${nameCandidate}!

  // You hav been shortlisted for ${role} at ${company}.

  // Please book a slot for interview by clicking on this link: https://www.solidhire.io

  // Thanks and Regards,
  // Team ${company}`,
  //         },
  //       });
  //       // console.log("Document written with ID: ", docRef.id);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //Email Trigger
  const triggerEmail = async (to, cc, body, subject) => {
    try {
      const docRef = await addDoc(collection(db, "mail"), {
        to: to,
        cc: cc ? cc : "",
        from: `${company.toUpperCase()} Hiring Team <hello@solidhire.io>`,
        message: {
          subject: company.toUpperCase() + " " + "|" + " " + subject,
          text: `
${body}

** Please note: Do not reply to this email. This email is sent from an unattended mailbox. Replies will not be read
`,
        },
      });
      // console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUser();
    getTodaysDate();

    setRole(params.id);
    uid && getCompany();

    uid && readData();
    uid && getEmailToggle();

    uid && getEmailBodyData();

    uid && getAllRoles();
  }, [uid]);

  useEffect(() => {
    uid && readData();
  }, [filter]);

  const filterCheck = (c) => {
    const check = defaultCandidate.some((s) => s.data.status == c);
    return check;
  };

  const alertToast = () => toast.warn("Please fill all details!");

  const successfulTransferToast = () => toast.success("Candidate transferred!");

  function validateText(str) {
    var tarea = str;
    if (tarea.indexOf("http://") == 0 || tarea.indexOf("https://") == 0) {
      return true;
    } else {
      return false;
    }
  }

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Status", key: "status" },
    { label: "Resume (link)", key: "resume" },
    { label: "Portfolio", key: "portfolio" },
    { label: "Linkedin", key: "linkedin" },
    { label: "Applied-On", key: "appliedOn" },
    { label: "Notes", key: "notes" },
    { label: "(if) Transfer From Role:", key: "transferredFrom" },
  ];

  const dataForCsv = async (candidate) =>
    setT(
      await Promise.all(
        candidate.map(async (c) => {
          return {
            name: c.data.name,
            email: c.data.email,
            status: c.data.status,
            resume: await resumeLink(c.data.email),
            portfolio: c.data.portfolio,
            linkedin: c.data.linkedin,
            appliedOn: c.data.date,
            notes: c.data.notesData
              ? c.data.notesData.map((n) => {
                  return ["{", n.date, n.status, n.notes, "}"];
                })
              : null,
            transferredFrom: c.data.transferredFrom
              ? c.data.transferredFrom
              : null,
          };
        })
      )
    );

  return (
    <>
      {!loading && candidate.length === 0 ? (
        <div className="flex justify-center">
          {filter !== "" ? setFilter("") : null}
          <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
              Sorry, No one applied yet.
            </h5>
            <p className="text-gray-700 text-base mb-4">
              Please try again later
            </p>
            <Link to="/">
              <button
                type="button"
                className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Home
              </button>
            </Link>
          </div>
        </div>
      ) : loading ? (
        <div className="relative z-10">
          <div className="fixed   top-52 left-0 right-0 text-center">
            <svg
              role="status"
              className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      ) : (
        <>
          <div className=" flex flex-col align-middle items-center justify-center z-10 my-5 rounded-lg ">
            {addNotes ? (
              <div className="relative  ">
                <div className="border-2 justify-center bottom-2/4 right-60  p-4 pt-0 rounded-md shadow-lg w-1/2 fixed z-20 bg-white">
                  <div className=" flex justify-between w-full m-1 text-right ">
                    <div>Candidate: {nameCandidate}</div>
                    <div
                      className="w-auto font-bold m-1 cursor-pointer"
                      onClick={() => setAddNotes(!addNotes)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.828"
                        height="17.828"
                      >
                        <path d="m2.828 17.828 6.086-6.086L15 17.828 17.828 15l-6.086-6.086 6.086-6.086L15 0 8.914 6.086 2.828 0 0 2.828l6.085 6.086L0 15l2.828 2.828z" />
                      </svg>
                    </div>
                  </div>

                  <div className="flex justify-center w-full ">
                    <table className="table-auto  rounded-lg ">
                      <thead className="rounded-md border-separate 	">
                        <tr className="bg-gray-800 text-left rounded-md border-separate	">
                          <th className="border border-black p-2 text-white w-auto">
                            Date
                          </th>
                          <th className="border border-black p-2 text-white">
                            Status
                          </th>
                          <th className="border border-black p-2 text-white w-auto">
                            Remarks
                          </th>
                          <th className="border border-black p-2 text-white">
                            {/* <button onClick={() => setAddNotes(!addNotes)}>
                            X
                          </button> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {notesDataArray
                          ? notesDataArray.map((d) => {
                              return (
                                <tr>
                                  <td className="border-2 pl-2">{d.date}</td>
                                  <td className="border-2 pl-2">{d.status}</td>
                                  <td className="border-2 pl-2">{d.notes}</td>
                                  <td className="border-2">
                                    <button
                                      className="m-1 inline-block px-3 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                      onClick={() => {
                                        // const newArray = usersArray.filter(
                                        //   (x) => x != e.target.id
                                        // );
                                        // console.log("newArray", newArray);

                                        // setNewUsersArray(newArray);

                                        const newArray = notesDataArray.filter(
                                          (x) => x !== d
                                        );
                                        setNotesDataArray2(newArray);
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                        <tr></tr>
                        <tr>
                          <td className="border-2 pl-2">
                            {/* <input placeholder="Name" value={todaysDate}></input> */}
                            <div>{todaysDate}</div>
                          </td>
                          <td className="border-2 pl-2">
                            {/* <input
                            placeholder="Email"
                            value={targetValue}
                          ></input> */}
                            <div>{targetValue}</div>
                          </td>
                          <td className="border-2 pl-2 ">
                            <textarea
                              placeholder="Remark"
                              onChange={(e) => setNotesString(e.target.value)}
                              value={notesString}
                              className="w-full"
                            ></textarea>
                          </td>
                          <td className="border-2 ">
                            <button
                              className="m-1 w-11/12 inline-block px-3 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                              onClick={() => {
                                notesString
                                  ? setNotesDataArray2([
                                      ...notesDataArray,
                                      {
                                        date: todaysDate,
                                        status: targetValue,
                                        notes: notesString,
                                      },
                                    ])
                                  : alertToast();
                              }}
                            >
                              Add
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="mb-5 w-2/3 font-semibold text-xl">{role}</div>
            <div className="flex justify-between w-2/3">
              {t ? (
                <CSVLink
                  filename={company + ".csv"}
                  // onClick={() => console.log(headers, t)}
                  data={t}
                  headers={headers}
                  className="flex w-1/2 justify-start items-start align-top mb-5 hover:underline"
                >
                  <img src="https://img.icons8.com/ios-glyphs/30/000000/export-csv.png"></img>
                  Download CSV file
                </CSVLink>
              ) : null}

              <div className="flex mr-5">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      type="button"
                      className=" border border-gray-300 bg-white dark:bg-gray-800 shadow-sm flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                      id="options-menu"
                      onClick={() => {
                        setDropdown(!dropdown);
                        setSettingsDropDown(false);
                      }}
                    >
                      {filter ? filter : "Filter"}
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
                      </svg>
                    </button>
                  </div>
                  {dropdown ? (
                    <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
                      <div
                        className="py-1 "
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {filter !== "applied" && filterCheck("applied") ? (
                          <a
                            className="block cursor-pointer  px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              setFilter("applied");
                              setDropdown(false);
                              setSelectedCandidates([]);
                            }}
                          >
                            <span className="flex flex-col">
                              <span>Applied</span>
                            </span>
                          </a>
                        ) : null}

                        {/* rejected */}
                        {filter !== "rejected" && filterCheck("rejected") ? (
                          <a
                            className="block cursor-pointer  px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              setFilter("rejected");
                              setDropdown(false);
                              setSelectedCandidates([]);
                            }}
                          >
                            <span className="flex flex-col">
                              <span>Rejected</span>
                            </span>
                          </a>
                        ) : null}

                        {filter !== "shortlisted" &&
                        filterCheck("shortlisted") ? (
                          <a
                            className="block cursor-pointer  px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              setFilter("shortlisted");
                              setDropdown(false);
                              setSelectedCandidates([]);
                            }}
                          >
                            <span className="flex flex-col">
                              <span>Shortlisted</span>
                            </span>
                          </a>
                        ) : null}

                        {filter !== "interviewed" &&
                        filterCheck("interviewed") ? (
                          <a
                            className="block cursor-pointer px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              setFilter("interviewed");
                              setDropdown(false);
                              setSelectedCandidates([]);
                            }}
                          >
                            <span className="flex flex-col">
                              <span>Interviewed</span>
                            </span>
                          </a>
                        ) : null}

                        {filter !== "interviewed-2" &&
                        filterCheck("interviewed-2") ? (
                          <a
                            className="block cursor-pointer px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              setFilter("interviewed-2");
                              setDropdown(false);
                              setSelectedCandidates([]);
                            }}
                          >
                            <span className="flex flex-col">
                              <span>Interviewed-2</span>
                            </span>
                          </a>
                        ) : null}

                        {filter !== "interviewed-3" &&
                        filterCheck("interviewed-3") ? (
                          <a
                            className="block cursor-pointer px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              setFilter("interviewed-3");
                              setDropdown(false);
                              setSelectedCandidates([]);
                            }}
                          >
                            <span className="flex flex-col">
                              <span>Interviewed-3</span>
                            </span>
                          </a>
                        ) : null}

                        {filter !== "selected" && filterCheck("selected") ? (
                          <a
                            className="block cursor-pointer px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              setFilter("selected");
                              setDropdown(false);
                              setSelectedCandidates([]);
                            }}
                          >
                            <span className="flex flex-col">
                              <span>Selected</span>
                            </span>
                          </a>
                        ) : null}
                        <a
                          className="block cursor-pointer px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                          role="menuitem"
                          onClick={() => {
                            setFilter("");
                            setDropdown(false);
                            setSortStatus(true);
                          }}
                        >
                          <span className="flex flex-col">
                            <span>Clear Filter</span>
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div>
                  {selectedCandidates.length > 0 ? (
                    <div className="flex-col relative">
                      <MdSettings
                        className={
                          settingsDropDown
                            ? `rotate-90 flex items-center ml-3 text-base font-normal h-9 w-9 cursor-pointer`
                            : `flex items-center ml-3 text-base font-normal h-9 w-9 cursor-pointer`
                        }
                        onClick={() => {
                          setSettingsDropDown(!settingsDropDown);
                          setDropdown(false);
                        }}
                      />
                      {settingsDropDown ? (
                        <div className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
                          <div
                            className="block cursor-pointer px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                            onClick={() => {
                              setTransferRolePopUp(!transferRolePopUp);
                              setSettingsDropDown(false);
                            }}
                          >
                            Transfer Candidate
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex items-center w-4/6 outline outline-1 rounded-lg mt-2 mb-2 ">
              <input
                className="pl-2 w-full"
                type="text"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Search Candidate"
              />
              {search ? (
                <MdClear
                  className="cursor-pointer"
                  onClick={() => setSearch("")}
                />
              ) : null}
            </div>

            <table className="table-auto w-2/3 border-collapse rounded-xl border-radius-lg">
              <thead className="rounded-md ">
                <tr className="bg-gray-800 text-left rounded-lg border-radius-lg">
                  <th className="border border-black p-2 text-white ">
                    <input
                      type="checkbox"
                      checked={selectedCandidates === candidate ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedCandidates(candidate);
                          // console.log("when selected", selectedCandidates);
                        } else if (!e.target.checked) {
                          setSelectedCandidates([]);
                          // console.log("when deselected", selectedCandidates);
                        }
                      }}
                    />
                  </th>
                  <th className="border border-black p-2 text-white">Name</th>
                  <th className="border border-black p-2 text-white">Email</th>
                  <th className="border border-black p-2 text-white">Resume</th>
                  <th className="border border-black p-2 text-white">
                    LinkedIn
                  </th>
                  <th className="border border-black p-2 text-white">
                    Portfolio
                  </th>
                  <th className="border border-black p-2 text-white">
                    Applied on
                  </th>
                  <th className="border border-black p-2 text-white">
                    Remarks
                  </th>
                  <th className="border border-black p-2 text-white h-auto flex justify-between items-center">
                    Status{" "}
                    {/* {filter === "" || filter === "sort" ? (
                      <button
                        onClick={() => {
                          sortStatus ? setFilter("sort") : setFilter("");
                          setSortStatus(!sortStatus);
                        }}
                      >
                        <Tippy content="Sort in descending order">
                          <img src="https://img.icons8.com/material-outlined/24/ffffff/sort.png" />
                        </Tippy>
                      </button>
                    ) : null} */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(candidate)} */}
                {candidate
                  .filter(
                    (c) =>
                      fuzzysearch(
                        search.toLowerCase(),
                        c.data.name.toLowerCase()
                      ) ||
                      fuzzysearch(
                        search.toLowerCase(),
                        c.data.email.toLowerCase()
                      ) ||
                      fuzzysearch(
                        search.toLowerCase(),
                        c.data.status.toLowerCase()
                      ) ||
                      fuzzysearch(
                        search.toLowerCase(),
                        c.data.transferredFrom
                          ? c.data.transferredFrom.toLowerCase()
                          : ""
                      )
                  )
                  .map((c, i) => {
                    // console.log("hello", c, candidate.indexOf(c));
                    // const i = candidate.findIndex(c);
                    return (
                      <tr className="hover:bg-gray-300 bg-gray-100" key={i}>
                        <td className="border border-black p-2">
                          <input
                            type="checkbox"
                            checked={
                              selectedCandidates.includes(c) ? true : false
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedCandidates([
                                  ...selectedCandidates,
                                  c,
                                ]);
                                // console.log("when selected", selectedCandidates);
                              } else if (!e.target.checked) {
                                setSelectedCandidates(
                                  selectedCandidates.filter(
                                    (candidate) => candidate !== c
                                  )
                                );
                                // console.log(
                                //   "when deselected",
                                //   selectedCandidates
                                // );
                              }
                            }}
                          />
                        </td>
                        <td
                          onClick={() => {
                            // setModal(true);
                            setUid(c.data.uid);
                          }}
                          className="border border-black p-2"
                        >
                          {c.data.name}
                        </td>
                        <td className="border border-black p-2">
                          {c.data.email}
                        </td>
                        <td
                          className="border border-black p-2 cursor-pointer hover:underline"
                          onClick={() => {
                            downloadResume(c.data.email);
                          }}
                        >
                          Open
                        </td>
                        <td className="border border-black p-2 cursor-pointer hover:underline">
                          {c.data.linkedin ? (
                            validateText(c.data.linkedin) ? (
                              <a href={c.data.linkedin} target="_blank">
                                Open
                              </a>
                            ) : (
                              <a
                                href={`https://${c.data.linkedin}`}
                                target="_blank"
                              >
                                Open
                              </a>
                            )
                          ) : null}
                        </td>

                        <td className="border border-black p-2 cursor-pointer hover:underline">
                          {c.data.portfolio ? (
                            validateText(c.data.portfolio) ? (
                              <a href={c.data.portfolio} target="_blank">
                                Open
                              </a>
                            ) : (
                              <a
                                href={`https://${c.data.portfolio}`}
                                target="_blank"
                              >
                                Open
                              </a>
                            )
                          ) : null}
                        </td>

                        <td className="border border-black p-2">
                          {moment(c.data.date).calendar()}
                        </td>

                        <td
                          className="border border-black p-2 cursor-pointer hover:underline"
                          // onClick={() => {
                          //   console.log(c.linkedin);
                          //   href = c.linkedin;
                          //   target = "_blank";
                          // }}
                          id={c.id}
                          onClick={(e) => {
                            setAddNotes(true);
                            setTargetId(e.target.id);
                            setTargetValue(c.data.status);
                            setNameCandidate(c.data.name);
                            // console.log(e.target.id, addNotes);

                            if (c.data.notesData === undefined) {
                              setNotesDataArray([]);
                            } else {
                              setNotesDataArray(c.data.notesData);
                            }
                          }}
                        >
                          Open
                        </td>

                        <td className="border border-black p-2 cursor-pointer">
                          <select
                            className="bg-inherit w-full cursor-pointer"
                            id={c.id}
                            value={
                              statusBox === i ? targetValue : c.data.status
                            }
                            onChange={(e) => {
                              // updateStatus(e.target.id, e.target.value)

                              setPopUp(true);
                              setNameCandidate(c.data.name);

                              setTargetId(e.target.id);
                              setTargetValue(e.target.value);

                              setStatusBox(i);
                            }}
                          >
                            <option className="bg-gray-300" value="applied">
                              applied
                            </option>
                            {/* rejected */}
                            <option className="bg-gray-300" value="rejected">
                              rejected
                            </option>
                            <option className="bg-gray-300" value="shortlisted">
                              shortlisted
                            </option>
                            <option className="bg-gray-300" value="interviewed">
                              interviewed
                            </option>
                            <option
                              className="bg-gray-300"
                              value="interviewed-2"
                            >
                              interviewed-2
                            </option>
                            <option
                              className="bg-gray-300"
                              value="interviewed-3"
                            >
                              interviewed-3
                            </option>
                            <option className="bg-gray-300" value="selected">
                              selected
                            </option>
                          </select>
                        </td>

                        {c.data.transferredFrom ? (
                          <Tippy
                            content={`Transferred from Role: ${c.data.transferredFrom}`}
                          >
                            <td className="bg-white ">
                              <MdOutlineInfo className="ml-1" />
                            </td>
                          </Tippy>
                        ) : null}
                      </tr>
                    );
                  })}

                {/* <tr>
                <td
                  className="border border-black p-2"
                  onMouseOver={(e) => console.log(e.target.value)}
                >
                  The Sliding Mr. Bones (Next Stop, Pottersville)
                </td>
                <td className="border border-black p-2">Malcolm Lockyer</td>
                <td className="border border-black p-2">1961</td>
              </tr> */}
              </tbody>
            </table>

            {/* {modal && <Popup uid={params.id} setModal={setModal} />} */}
            {/* pop-up for status confirmation */}

            {/* {popUp ? (
              <div className="z-20">
                <div className=" fixed top-1/3 ">
                  <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
                    <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                      Are you sure?
                    </h5>
                    
                    <button
                      type="button"
                      className=" inline-block px-6 mr-2 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                      onClick={() => {
                        updateStatus(targetId, targetValue);
                      }}
                    >
                      confirm
                    </button>
                    <button
                      type="button"
                      className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                      onClick={() => {
                        setPopUp(false);
                        setTargetId("");
                        setTargetValue("");
                        readData();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            ) : null} */}

            {/* Experimental pop up*/}
            {popUp ? (
              <div className="relative">
                <div className="fixed left-0 top-0 w-screen h-screen">
                  <div className="flex justify-center items-center h-full">
                    <div className=" w-1/4">
                      <div className="relative ">
                        <div class="modal-content  border-none shadow-lg relative flex flex-col  pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                          <div class="modal-body relative p-4">
                            <h1 className="font-semibold mb-2">
                              <span className="font-normal">Candidate:</span>{" "}
                              {nameCandidate}
                            </h1>

                            <p>Change Status to:</p>

                            <div className="w-full flex justify-center items-center border-2 mt-2 rounded-md">
                              {targetValue}
                            </div>

                            {/* <select
                              className="w-full flex justify-center items-center border-2 mt-2 rounded-md"
                              value={targetValue}
                              onChange={(e) => {
                                setTargetValue(e.target.value);
                              }}
                            >
                              <option
                                className="bg-gray-300 text-center"
                                value="applied"
                              >
                                applied
                              </option>

                              <option
                                className="bg-gray-300 text-center"
                                value="rejected"
                              >
                                rejected
                              </option>
                              <option
                                className="bg-gray-300 text-center"
                                value="shortlisted"
                              >
                                shortlisted
                              </option>
                              <option
                                className="bg-gray-300 text-center"
                                value="interviewed"
                              >
                                interviewed
                              </option>
                              <option
                                className="bg-gray-300 text-center"
                                value="interviewed-2"
                              >
                                interviewed-2
                              </option>
                              <option
                                className="bg-gray-300 text-center"
                                value="interviewed-3"
                              >
                                interviewed-3
                              </option>
                              <option
                                className="bg-gray-300 text-center"
                                value="selected"
                              >
                                selected
                              </option>
                            </select> */}

                            <div className="flex justify-between mt-2">
                              <div>Send Email?</div>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  setSendEmailToggle(!sendEmailToggle)
                                }
                                checked={sendEmailToggle}
                              />
                            </div>
                            {sendEmailToggle ? (
                              <>
                                <div className="border-2 mt-2 rounded-md">
                                  <div className="text-slate-500 pl-2">
                                    {
                                      candidate.filter(
                                        (c) => c.id === targetId
                                      )[0].data.email
                                    }
                                  </div>
                                </div>

                                <div className="border-2 mt-2 rounded-md">
                                  <input
                                    className=" w-full pl-2"
                                    type="text"
                                    placeholder="Cc Email (optional)"
                                    onChange={(e) => {
                                      setCcEmail(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="border-2 mt-2 rounded-md">
                                  <textarea
                                    className=" w-full pl-2 h-16"
                                    placeholder="Subject*"
                                    onChange={(e) => {
                                      //update subject of targetValue in emailBody state depending on changes in subject
                                      setEmailBody((prevState) => ({
                                        ...prevState,
                                        [targetValue]: {
                                          ...prevState[targetValue],
                                          subject: e.target.value,
                                        },
                                      }));
                                    }}
                                    value={
                                      targetValue
                                        ? emailBody[targetValue].subject
                                        : ""
                                    }
                                  ></textarea>
                                </div>
                                {emailSubjectError ? (
                                  <div className="text-red-600">
                                    Please Enter Subject!
                                  </div>
                                ) : null}

                                <div className="border-2 mt-2 rounded-md">
                                  <textarea
                                    className=" w-full pl-2 h-32"
                                    placeholder="Body*"
                                    onChange={(e) => {
                                      setEmailBody((prevState) => ({
                                        ...prevState,
                                        [targetValue]: {
                                          ...prevState[targetValue],
                                          body: e.target.value,
                                        },
                                      }));
                                    }}
                                    value={
                                      targetValue
                                        ? emailBody[targetValue].body
                                        : null
                                    }
                                  ></textarea>
                                </div>
                                {emailBodyError ? (
                                  <div className="text-red-600">
                                    Please Enter Body of the Email!
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                setPopUp(false);
                                setTargetId("");
                                setTargetValue("");
                                setCcEmail("");
                                readData();
                                setEmailBodyError(false);
                                setEmailSubjectError(false);
                              }}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                              onClick={() => {
                                if (
                                  sendEmailToggle &&
                                  !!emailBody[targetValue].subject &&
                                  !!emailBody[targetValue].body
                                ) {
                                  updateStatus(targetId, targetValue);

                                  triggerEmail(
                                    candidate.filter(
                                      (c) => c.id === targetId
                                    )[0].data.email,
                                    ccEmail,
                                    emailBody[targetValue].body,
                                    emailBody[targetValue].subject
                                  );
                                } else if (!sendEmailToggle) {
                                  updateStatus(targetId, targetValue);
                                } else if (
                                  sendEmailToggle &&
                                  emailBody[targetValue].subject === ""
                                ) {
                                  if (
                                    sendEmailToggle &&
                                    emailBody[targetValue].body === ""
                                  ) {
                                    setEmailBodyError(true);
                                  } else {
                                    setEmailBodyError(false);
                                  }

                                  setEmailSubjectError(true);
                                } else if (
                                  sendEmailToggle &&
                                  emailBody[targetValue].body === ""
                                ) {
                                  if (
                                    sendEmailToggle &&
                                    emailBody[targetValue].subject === ""
                                  ) {
                                    setEmailSubjectError(true);
                                  } else {
                                    setEmailSubjectError(false);
                                  }

                                  setEmailBodyError(true);
                                }
                                // console.log(ccEmail);
                              }}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Pop up for tranferring Candidate */}
            {transferRolePopUp ? (
              <div className="relative">
                <div className="fixed left-0 top-0 w-screen h-screen">
                  <div className="flex justify-center items-center h-full">
                    <div className=" w-1/4">
                      <div className="relative ">
                        <div class="modal-content  border-none shadow-lg relative flex flex-col  pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                          <div class="modal-body relative p-4">
                            <h1 className="font-semibold mb-2">
                              <span className="font-normal">Candidate(s):</span>{" "}
                              {selectedCandidates
                                .map((c) => c.data.name)
                                .join(", ")}
                            </h1>

                            <p>Transfer candidate(s) to Role:</p>

                            <div className="w-full flex justify-center items-center border-2 mt-2 rounded-md">
                              <select
                                onChange={(e) => {
                                  setTransferRole(e.target.value);
                                  // console.log("transferRole", e.target.value);
                                }}
                                // value={role}
                              >
                                {allRoles.map((item) => {
                                  if (item.title !== role) {
                                    return (
                                      <option value={item.title}>
                                        {item.title}
                                      </option>
                                    );
                                  }
                                })}
                              </select>
                            </div>
                            {/* {transferRoleError ? (
                              <div className="text-red-700 text-sm">
                                Please select role different that current one
                              </div>
                            ) : null} */}
                          </div>
                          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                setTransferRolePopUp(false);
                                setTransferRole("");
                                setTransferRoleError(false);
                              }}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                              onClick={() => {
                                if (
                                  transferRole !== "" &&
                                  transferRole !== role
                                ) {
                                  readData();
                                  transferCandidatesToSelectedRole();

                                  setSelectedCandidates([]);
                                  setTransferRolePopUp(false);
                                } else {
                                  console.log("Please select a role");
                                }
                              }}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <ToastContainer />
        </>
      )}
    </>
  );
}

export default HomePage;
