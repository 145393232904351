import React, { useState, useEffect } from "react";
import { db } from "../firebase/Config";
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { Link, Redirect } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { map } from "@firebase/util";
import SendGrid from "./SendGrid";

function Roles(props) {
  const [roles, setRoles] = useState([]);
  const [uid, setUid] = useState("");
  const [qs, setQs] = useState(null);
  const [modal, setModal] = useState(false);
  const [clickedRole, setClickedRole] = useState("");
  const [filteredRole, setFilteredRole] = useState([]);
  const [titleToDelete, setTitleToDelete] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [applications, setApplications] = useState([]);
  const [comp, setComp] = useState(null);

  // const uid = "";
  const getUser = () => {
    // console.log("Inside getUser Func");
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      // const uid = user.uid;
      setUid(user.uid);

      // console.log("uid", uid);
    } else {
      console.log("user is not signed in");
    }
  };

  const readData = async () => {
    // console.log("Inside readData Func");
    const arr = [];
    // console.log("inside readData", uid);

    const q = query(
      collection(db, "roles"),

      where("uid", "array-contains", uid),
      where("archive", "==", true)
    );

    const querySnapshot = await getDocs(q);
    // console.log(Date(serverTimestamp()).getDate());

    // console.log("querySnapshoot", querySnapshot);
    // console.log(uid);
    // console.log(querySnapshot.docs);

    if (querySnapshot.docs.length > 0) {
      // setRoles(querySnapshot.docs);

      // setQs(querySnapshot.docs);
      querySnapshot.forEach((doc) => {
        // console.log(doc);
        arr.push(doc.data());
        // console.log("Yooo", arr);

        // console.log(arr);
        // setRoles(arr);
        // console.log(roles);
      });
      setRoles(arr);
      setComp(arr[0].company);
    } else {
      // console.log("querySnapshoot", querySnapshot);
      // console.log(querySnapshot.docs);
      window.location.pathname = `/introduction`;
      // setQs(undefined);
    }

    // console.log("Roles", roles);

    // console.log("sagar wale comment", querySnapshot.empty, querySnapshot.size);

    // console.log("Qs ke docs", querySnapshot.docs);

    // console.log("Type of", Array.isArray(querySnapshot));
    // console.log("QS Size", querySnapshot.size);

    // if (querySnapshot.size === 0) {
    //   window.location.pathname = `/introduction`;
    // }

    // querySnapshot.forEach((doc) => {
    //   console.log(doc);
    //   arr.push(doc.data());
    //   console.log("Yooo ", arr);

    //   console.log(arr);
    //   setRoles(arr);
    //   console.log(roles);
    // });
  };

  const deleteRole = async (t) => {
    const q = query(collection(db, "roles"), where("title", "==", t));

    try {
      const querySnapshot = await getDocs(q);
      const id = querySnapshot.docs[0].id;
      await deleteDoc(doc(db, "roles", id));
      console.log("doc deleted");
      setPopUp(false);
      setModal(false);
      readData();
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getUser();
    comp && getApplications();
    uid && readData();
  }, [uid, comp]);

  useEffect(() => {
    setFilteredRole(roles.filter((role) => role.title === clickedRole));
    clickedRole && setModal(true);
  }, [clickedRole]);

  const getApplications = async () => {
    const arr = [];
    const q = query(
      collection(db, "candidates"),
      where("userId", "array-contains", uid),
      where("company", "==", comp)
    );
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });

      // console.log(arr.length);
      setApplications(arr);
    } catch (e) {
      console.log(e);
    }

    // setApplications(arr.length());

    // setDocId(docs);
    // console.log("candidate state", candidate, docId);
  };

  const updateArchive = async (t) => {
    const q = query(collection(db, "roles"), where("title", "==", t));

    try {
      const querySnapshot = await getDocs(q);
      const id = querySnapshot.docs[0].id;
      await updateDoc(doc(db, "roles", id), {
        archive: false,
      });

      console.log("doc Activated");
      setModal(!modal);
      readData();
    } catch (e) {
      console.log(e.message);
    }
  };

  const filterApplicants = (r) => {
    let score = 0;
    applications.forEach((a) => {
      if (a.role === r) {
        score += 1;
      }
      score += 0;
    });
    // console.log("score", score);
    return score;
  };

  return (
    <div className="flex justify-center">
      <div className="flex-col content-center text-center w-full">
        <div className="mt-5 ">{`Archive(${roles.length})`}</div>
        {/* table starts */}
        <div className="flex justify-center rounded-xl ">
          <table className="w-3/5 text-sm mt-2 text-left rounded-md text-gray-500 dark:text-gray-400">
            <thead className="text-xs rounded text-black uppercase bg-gray-150 dark:bg-gray-200 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Title
                </th>
                <th scope="col" className="px-6 py-3">
                  Department
                </th>
                <th scope="col" className="px-6 py-3">
                  Location
                </th>
                <th scope="col" className="px-6 py-3">
                  Posted-on
                </th>
                <th scope="col" className="px-6 py-3">
                  Applied
                </th>
                {/* <th scope="col" class="px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th> */}
              </tr>
            </thead>

            {roles.map((role) => {
              return (
                <>
                  <tbody
                    className="text-gray-800"
                    id={role.title}
                    key={Date.now()}
                    onClick={(e) => {
                      setClickedRole(e.target.id);
                    }}
                  >
                    <tr
                      className="border-b cursor-pointer dark:border-gray-700 odd:bg-white even:bg-gray-50 "
                      id={role.title}
                      onClick={(e) => {
                        setClickedRole(e.target.id);
                      }}
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-black whitespace-nowrap"
                        id={role.title}
                      >
                        {role.title}
                      </th>
                      <td className="px-6 py-4" id={role.title}>
                        {role.department}
                      </td>
                      <td className="px-6 py-4" id={role.title}>
                        {role.location}
                      </td>
                      <td className="px-6 py-4 " id={role.title}>
                        {role.date}
                      </td>
                      <td className="px-6 py-4 " id={role.title}>
                        {filterApplicants(role.title)}
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </table>
        </div>
        {/* {roles.map((role) => {
          return (
            //table starts

            //table ends

            <div className="flex justify-center ">
              <div
                id={role.title}
                className="block p-2 border-2 rounded-lg shadow-lg bg-white max-w-lg cursor-pointer m-4 w-3/4 text-left"
                onClick={(e) => {
                  setClickedRole(e.target.id);
                }}
              >
                <h5
                  className="text-gray-900 text-xl leading-tight font-medium mb-2"
                  id={role.title}
                >
                  Title: {role.title}
                </h5>
                <p className="text-gray-700 text-base mb-4" id={role.title}>
                  Department: {role.department}
                </p>
              </div>
            </div>
          );
        })} */}
      </div>
      <div>
        {modal && clickedRole ? (
          <div>
            {/* Modal  */}

            <div
              className="modal rounded-md shadow-black fade fixed top-40 left-1/4 bg-white shadow-lg  w-1/2 justify-self-center  outline-none overflow-auto h-3/5"
              id={filteredRole[0].title}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog relative w-auto pointer-events-none">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    <h5
                      className="text-xl font-medium leading-normal text-gray-800"
                      id="exampleModalLabel"
                    >
                      {filteredRole[0].title}
                    </h5>
                    <div>
                      <button
                        type="button"
                        id={filteredRole[0].title}
                        className="px-6
                      py-2.5
                      bg-green-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-green-700 hover:shadow-lg
                      focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-green-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          updateArchive(e.target.id);
                        }}
                      >
                        Activate
                      </button>

                      <button
                        type="button"
                        id={filteredRole[0].title}
                        className="px-6
                      py-2.5
                      bg-red-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-red-700 hover:shadow-lg
                      focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-red-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          setTitleToDelete(e.target.id);
                          setPopUp(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div className="modal-body relative p-4">
                    <span className="font-semibold">Department:</span>{" "}
                    {filteredRole[0].department}
                  </div>
                  <div className="modal-body relative p-4">
                    <span className="font-semibold">Type:</span>{" "}
                    {filteredRole[0].type}
                  </div>
                  <div className="modal-body relative p-4">
                    <span className="font-semibold">Location:</span>{" "}
                    {filteredRole[0].location}
                  </div>
                  <p className="modal-body relative p-4">
                    <span className="font-semibold">About the role:</span>{" "}
                    {filteredRole[0].about}
                  </p>
                  <div className="modal-body relative p-4">
                    <span className="font-semibold">What you'll do:</span>{" "}
                    {filteredRole[0].willDo}
                  </div>
                  <div className="modal-body relative p-4">
                    <span className="font-semibold">Must have:</span>{" "}
                    {filteredRole[0].mustHave}
                  </div>
                  <div className="modal-body relative p-4">
                    <span className="font-semibold">
                      Additional requirements:
                    </span>{" "}
                    {filteredRole[0].hobbies}
                  </div>
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                    <button
                      type="button"
                      className="px-6
                            py-2.5
                            bg-purple-600
                            text-white
                            font-medium
                            text-xs
                            leading-tight
                            uppercase
                            rounded
                            shadow-md
                            hover:bg-purple-700 hover:shadow-lg
                            focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0
                            active:bg-purple-800 active:shadow-lg
                            transition
                            duration-150
                            ease-in-out"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setModal(false);
                        setClickedRole("");
                        setPopUp(false);
                      }}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="px-6
                    py-2.5
                    bg-blue-600
                    text-white
                    font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-blue-700 hover:shadow-lg
                    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-blue-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out
                    ml-1"
                      onClick={() => {
                        // props.setCandidate(filteredRole[0].title);
                        props.onRoleSelect(filteredRole[0].title);
                      }}
                    >
                      Show Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {popUp ? (
        <div>
          <div className=" fixed left-1/3 ">
            <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
              <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                Are you sure?
              </h5>
              {/* <p className="text-gray-700 text-base mb-4">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p> */}
              <button
                type="button"
                className=" inline-block px-6 mr-2 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={() => deleteRole(titleToDelete)}
              >
                confirm
              </button>
              <button
                type="button"
                className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={() => {
                  setPopUp(false);
                  setTitleToDelete("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Roles;
