import React from "react";

import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { app, db, storage } from "../firebase/Config";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  setDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import validator from "validator";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function JobBoard() {
  const [uid, setUid] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [editedAboutSection, setEditedAboutSection] = useState("");
  const [docId, setDocId] = useState("");
  const [editLogoFile, setEditLogoFile] = useState(null);
  const [editAboutCompanyToggle, setEditAboutCompanyToggle] = useState(false);
  const [editLogoToggle, setEditLogoToggle] = useState(false);

  const getUser = () => {
    // console.log("Inside getUser Func");
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      // const uid = user.uid;
      setUid(user.uid);
      // console.log("uid", uid);

      // console.log("uid", uid);
    } else {
      console.log("user is not signed in");
    }
  };

  const getCompany = async () => {
    const q = query(collection(db, "company"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      setCompanyName(doc.data().name);
      setAboutCompany(doc.data().about);
      setDocId(doc.id);

      setEditedAboutSection(doc.data().about);

      // console.log("companyName", companyName);
    });
  };

  const downloadLogo = () => {
    // Create a reference to the file we want to download
    const storage = getStorage();
    const starsRef = ref(storage, "logo/" + companyName + ".jpg");

    // Get the download URL
    getDownloadURL(starsRef)
      .then((url) => {
        // Insert url into an <img> tag to "download"
        // console.log(url);
        setLogoUrl(url);
        // console.log("logoUrl", logoUrl);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  const editCompanyLogo = async () => {
    const file = editLogoFile;
    // console.log(file);
    const storageRef = ref(storage, "logo/" + companyName + ".jpg");
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log("File available at", downloadURL);
          setLogoUrl(downloadURL);
        });
      },
      toast.success("Logo Updated Successfully"),
      setEditLogoToggle(!editLogoToggle),
      setEditLogoFile(null)
    );
  };

  const editAboutCompany = async () => {
    // console.log("insideAboutCompany", editedAboutSection);
    const aboutCompanyRef = doc(db, "company", docId);
    await updateDoc(aboutCompanyRef, {
      about: editedAboutSection,
    }).then(() => {
      getCompany();
    });
    toast.success("About Company Updated Successfully");
    setEditAboutCompanyToggle(!editAboutCompanyToggle);
  };

  useEffect(() => {
    getUser();
  });

  useEffect(() => {
    uid && getCompany();
    uid && downloadLogo();
  }, [uid, companyName, aboutCompany]);

  const successToast = (msg) => toast.success(msg);
  const alertToast = (alert) => toast.warn(alert);

  return (
    <div className=" flex justify-center">
      <div className="w-2/3 mt-5">
        <div className="text-xl font-semibold">Job Board:</div>
        <div className="link" id="link">
          https://app.solidhre.io/company/{companyName}
        </div>
        <div className="mt-2 mb-5">
          <Link
            to={`company/${companyName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              class="inline-block m-2 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              Visit Job Board
            </button>
          </Link>

          <button
            type="button"
            class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            data-clipboard-action="copy"
            data-clipboard-target="#link"
            onClick={() =>
              navigator.clipboard
                .writeText(`https://app.solidhire.io/company/${companyName}`)
                .then(successToast("Link Copied"))
            }
          >
            Copy Job Board's Link
          </button>
        </div>

        <div className="text-xl font-semibold">Company Details:</div>
        <div className="p-2">
          <div className="mt-2">
            <span className="font-semibold">Company Name:</span>{" "}
            {companyName.toUpperCase()}
          </div>
          {/* code for displaying company logo and about company with edit option */}
          <div className="mt-2">
            <div className="font-semibold">Logo:</div>
            <img
              className="w-auto h-10 mt-2 m-2"
              src={logoUrl ? logoUrl : "https://via.placeholder.com/150"}
              alt="company logo"
            />
            {editLogoToggle === false ? (
              <button
                type="button"
                className="inline-block mt-2 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={() => setEditLogoToggle(!editLogoToggle)}
              >
                Edit
              </button>
            ) : null}
            {editLogoToggle ? (
              <div>
                <input
                  class="form-control
                        mt-2
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  type="file"
                  id="formFile"
                  onChange={(e) => setEditLogoFile(e.target.files[0])}
                ></input>
                <button
                  type="button"
                  className="mx-2 inline-block mt-2 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() =>
                    editLogoFile
                      ? editCompanyLogo()
                      : alertToast("Please select a file")
                  }
                >
                  Update
                </button>
                <button
                  type="button"
                  className="inline-block mt-2 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => setEditLogoToggle(!editLogoToggle)}
                >
                  Cancel
                </button>
              </div>
            ) : null}
          </div>
          <div className="mt-4   flex flex-col">
            <span className="font-semibold -ml-1">About Company:</span>
            {/* {console.log(editAboutCompanyToggle)} */}
            {editAboutCompanyToggle ? (
              <>
                <div className="">
                  <div className="border rounded-md p-2">
                    <textarea
                      type="text"
                      autoFocus
                      id="textArea"
                      className="h-64 w-full"
                      value={editedAboutSection}
                      onChange={(e) => setEditedAboutSection(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    type="button"
                    className="mx-2 inline-block mt-2 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() =>
                      editedAboutSection
                        ? editAboutCompany()
                        : alertToast("Please enter some text")
                    }
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mx-2 inline-block mt-2 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() =>
                      setEditAboutCompanyToggle(!editAboutCompanyToggle)
                    }
                  >
                    cancel
                  </button>
                </div>
              </>
            ) : (
              <div>
                <div className="border rounded-md p-2">{aboutCompany}</div>
                <button
                  type="button"
                  className=" inline-block mt-2 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => setEditAboutCompanyToggle(true)}
                >
                  Update
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default JobBoard;
