import { app } from "./Config";

import { getAuth, signOut } from "firebase/auth";

export const signOutG = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      console.log("signed Out");
      window.location.pathname = "/";
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
      console.log("sign out not working");
      // console.log(error.message);
    });
};
